<template>
  <div class="container">
    <!-- Left Panel -->
    <div class="left-panel">
      <div class="logo-section">
        <img :src="require('@/assets/website-logo.png')" alt="Website Logo" class="website-logo" @error="handleImageError">
        <img :src="require('@/assets/project-logo.png')" alt="Project Logo" class="project-logo" @error="handleImageError">
        <h1 class="project-title">SEE IT CHANGE IT</h1>
        <p class="project-subtitle">SMALLRIG AWARDS' WORLDWIDE INVITATION BEGINS</p>
        <button class="btn btn-home">Judging System</button>
      </div>
    </div>

    <!-- Right Panel -->
    <div class="right-panel">
      <div class="login-form">
        <h2>Login to the {{ roleText }} system</h2>
        
        <div class="role-switcher">
          <span 
            class="role-label"
            @click="switchRole"
          >
            {{ switcherLabel }}
          </span>
        </div>

        <div class="form-group">
          <label class="label-account">Account</label>
          <input type="text" class="input-field" v-model="username" placeholder="Account">
        </div>

        <div class="form-group">
          <input type="password" class="input-field" v-model="password" placeholder="Password">
        </div>

        <button class="btn btn-primary" style="width: 100%" @click="handleLogin">
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { useRouter } from 'vue-router'
import CryptoJS from 'crypto-js'

export default {
  name: 'LoginPage',
  props: {
    defaultRole: {
      type: String,
      required: true,
      validator: value => ['admin', 'judge'].includes(value)
    }
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  created() {
    // Check localStorage for existing credentials
    const token = localStorage.getItem('token')
    const userRole = localStorage.getItem('userRole')
    
    if (token && userRole) {
      // Redirect based on user role
      if (userRole === '1') {
        this.router.push('/admin/films')
      } else if (userRole === '2') {
        this.router.push('/judge/films')
      }
    }
  },
  data() {
    return {
      username: '',
      password: '',
      currentRole: this.defaultRole,
      imageLoadError: false
    }
  },
  watch: {
    defaultRole: {
      immediate: true,
      handler(newRole) {
        this.currentRole = newRole
      }
    }
  },
  computed: {
    roleText() {
      return this.currentRole === 'admin' ? 'Organizer\'s' : 'Judge\'s'
    },
    switcherLabel() {
      return this.currentRole === 'admin' ? 'I am a judge' : 'I am organizer'
    }
  },
  methods: {
    switchRole() {
      const newRole = this.currentRole === 'admin' ? 'judge' : 'admin'
      this.router.push(`/${newRole}`)
    },
    async handleLogin() {
      try {
        // 修改加密部分的代码
        const key = 'smallrig2025secretkey123';
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16字节的IV

        const encrypted = CryptoJS.AES.encrypt(this.password, CryptoJS.enc.Utf8.parse(key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        const encryptedPassword = encrypted.toString();

        const response = await axios.post('/auth/login', {
          username: this.username,
          password: encryptedPassword
        })

        if (response.data.code === 200) {
          const userData = response.data.data
          localStorage.setItem('token', userData.token)
          localStorage.setItem('userRole', userData.role)
          localStorage.setItem('userId', userData.id)
          localStorage.setItem('username', userData.username)

          if (userData.role === 1) {
            this.router.push('/admin/films')
          } else if (userData.role === 2) {
            this.router.push('/judge/films')
          }
        } else {
          alert(response.data.msg || 'Login failed')
        }
      } catch (error) {
        console.error('Login error:', error)
        alert('Login failed, please check your network connection')
      }
    },
    handleImageError(e) {
      console.error('Image failed to load:', e.target.src)
      e.target.src = ''
      this.imageLoadError = true
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.left-panel {
  width: 65%;
  background-color: var(--dark-bg);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* 使其成为定位上下文 */
  /* overflow: hidden; */ /* 移除此行以调试 */
  z-index: 1; /* 确保在其他元素之上 */
}

.left-panel::after {
  content: '';
  position: absolute; /* 绝对定位 */
  bottom: 0; /* 靠下对齐 */
  left: 0;
  width: 100%; /* 充满宽度 */
  height: 361px; /* 增加高度以确保显示 */
  background-image: url('@/assets/bg-bottom.png'); /* 设置背景图 */
  background-size: cover; /* 使背景图覆盖整个区域 */
  background-position: bottom; /* 背景图从底部开始 */
  z-index: -1; /* 确保背景图在内容后面 */
}

.btn {
  background-color: #000; /* 设置按钮背景为黑色 */
  color: white; /* 设置文字颜色为白色 */
  border: none; /* 移除边框 */
  border-radius: 8px; /* 添加圆角 */
  padding: 0.8rem; /* 添加内边距 */
  transition: all 0.3s ease; /* 添加过渡效果 */
}

.input-field {
  width: 100%; /* 确保输入框占满宽度 */
  padding: 0.8rem; /* 添加内边距 */
  border: 1px solid #ddd; /* 添加边框 */
  border-radius: 8px; /* 添加圆角 */
  font-size: 1rem; /* 设置字体大小 */
  margin-bottom: 1rem; /* 添加底部间距 */
}

.input-field:focus {
  border-color: var(--primary-color); /* 聚焦时改变边框颜色 */
  outline: none; /* 移除默认的聚焦轮廓 */
}

.btn-home {
  background-color: transparent;
  background-image: url('@/assets/home_page_button_bg.png');
  background-size: contain; /* 保持图片比例 */
  background-repeat: no-repeat;
  background-position: center;
  color: #ddd;
  border: none; /* 移除边框，因为使用背景图 */
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.btn-home:hover {
  opacity: 0.9;
  /* 移除原来的 box-shadow，因为现在使用背景图 */
}

.label-account {
  background-image: url('@/assets/account_bg.png');
  background-size: 34px 10px;
  background-repeat: no-repeat;
  background-position: left bottom;
  font-weight: bold;
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
  padding-left: 0px; /* 为背景图预留空间，根据实际图片大小调整 */
  height: 24px; /* 设置固定高度，根据实际图片大小调整 */
  line-height: 24px; /* 与 height 相同，使文字垂直居中 */
}

.right-panel {
  width: 35%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 1rem;
}

.login-form h2 {
  text-align: left;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.role-switcher {
  text-align: left;
  margin-bottom: 2rem;
}

.role-label {
  color: grey;
  cursor: pointer;
  text-decoration: underline;
}

.role-label:hover {
  opacity: 0.8;
}

.logo-section {
  text-align: center;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  padding-right: 2rem;
}

.website-logo {
  width: 360px;
  align-self: flex-end;
}

.project-logo {
  width: 180px;
  margin-top: 3rem;
}

.project-title {
  color: white;
  font-size: 4rem;
}

.project-subtitle {
  color: #ddd;
  font-size: 1.2rem;
  font-family: 'HarmonyOS Sans SC', sans-serif;
  letter-spacing: 0.2em; /* 添加字间距，可以根据需要调整这个值 */
}
</style>
