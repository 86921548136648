<template>
  <div class="admin-layout">
    <!-- 左侧导航栏 -->
    <div class="sidebar">
      <div class="logo">
        <img :src="require('@/assets/project-logo.png')" alt="Project Logo">
      </div>
      <nav class="nav-tabs">
        <router-link to="/admin/films" class="nav-item" :class="{ active: $route.path === '/admin/films' }">
          <img src="@/assets/tab-icon1.png" alt="Judge Icon" class="nav-icon">
          <span>Judge Management</span>
        </router-link>
        <router-link to="/admin/accounts" class="nav-item" :class="{ active: $route.path === '/admin/accounts' }">
          <img src="@/assets/tab-icon3.png" alt="Account Icon" class="nav-icon">
          <span>Account Management</span>
        </router-link>
      </nav>
    </div>

    <!-- 右侧内容区 -->
    <div class="main-content">
      <div class="header-section">
        <div class="title-section">
          <h1 class="page-title">
            {{ currentView === 'detail' ? 'Details of the Review Film' : 'SmallRig Awards' }}
          </h1>
          <div v-if="currentView === 'detail'" 
              class="back-button"
              @click="currentView = 'list'">
            Back to film list
          </div>
        </div>
        <div class="user-profile">
          <img 
            :src="require(`@/assets/avatar/${userInfo.avatar}`)" 
            alt="User Avatar" 
            class="user-avatar"
            @click="showUserModal = true"
          >
        </div>
      </div>
      
      <div class="content-wrapper">
        <!-- 列表视图 -->
        <div v-if="currentView === 'list'">
          <!-- 添加状态标签页 -->
          <div class="view-tabs">
            <button 
              v-for="tab in tabs" 
              :key="tab.value"
              :class="['view-tab', { active: currentTab === tab.value }]"
              @click="switchTab(tab.value)"
            >
              {{ tab.label }}
              <span v-if="statusCounts[tab.value]" class="count-badge">
                {{ statusCounts[tab.value] }}
              </span>
            </button>
          </div>
          <!-- Search and filter section -->
          <div class="search-filter-section">
            <div class="search-box">
              <div class="search-field-select">
                <select v-model="searchField">
                  <option v-for="field in searchFields" 
                          :key="field.value" 
                          :value="field.value">
                    {{ field.label }}
                  </option>
                </select>
              </div>
              <div class="search-input-wrapper">
                <input 
                  type="text" 
                  v-model="filmSearchQuery"
                  @keyup.enter="handleSearch"
                  placeholder="Enter search terms..."
                >
                <button class="search-button" @click="handleSearch">
                  Search
                </button>
              </div>
            </div>
            
            <div class="filter-dropdown">
              <div class="filter-title">Mark</div>
              <div class="filter-select-wrapper">
                <select v-model="markFilter" @change="handleSearch">
                  <option v-for="option in markOptions" 
                          :key="option.value" 
                          :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>

            <!-- 添加批量指定评委按钮 -->
            <button 
              class="batch-assign-button"
              @click="handleBatchAssign"
            >
              Batch Assign Judges
            </button>
          </div>
          <!-- Search input and filter dropdown -->
          <div class="content-card">
            <div class="films-table">
              <table>
                <thead>
                  <tr>
                    <th>Work</th>
                    <th>Duration</th>
                    <th>Appointed Judges</th>
                    <th>Mark</th>
                    <th>Score</th>
                    <th>Decision</th>
                    <th>Registration Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="film in films" 
                      :key="film.id" 
                      @click="showFilmDetail(film)"
                      class="film-row">
                    <td>
                      <div class="title-cell">
                        <div class="film-title">{{ film.title }}</div>
                        <div class="film-directors">
                          Director: {{ getDirectorNames(film) }}
                        </div>
                      </div>
                    </td>
                    <td>{{ formatDuration(film.duration) }}</td>
                    <td>
                      <span 
                        v-if="currentTab !== 3"
                        class="judge-status-text clickable-text" 
                        @click.stop="assignJudges(film.id)"
                      >
                        {{ hasAssignedJudges(film) ? 'Judges appointed' : 'Click to appoint judges' }}
                      </span>
                      <span v-else>--</span>  <!-- Show placeholder for Not shortlisted tab -->
                    </td>
                    <td>
                      <div v-for="mark in getCurrentRoundMarks(film)" :key="mark.judge_name" class="judge-item">
                        <span :class="getMarkClass(mark.mark)" class="mark-tag">{{ mark.mark }}</span>
                      </div>
                    </td>
                    <td>
                      <div v-for="score in getCurrentRoundScores(film)" :key="score.judge_name" class="judge-item">
                        <span class="score-wrapper">
                          {{ score.score || '0' }}
                          <img src="@/assets/star-icon.png" alt="Star" class="star-icon">
                        </span>
                      </div>
                    </td>
                    <td>
                      <select 
                        v-if="currentTab === 1" 
                        :value="film.status"
                        @change.stop="updateFilmStatus(film.id, $event.target.value)"
                        @click.stop
                        class="status-select"
                      >
                        <option value="1">Not selected</option>
                        <option value="2">Shortlisted</option>
                        <option value="3">Not chosen</option>
                      </select>
                      <select 
                        v-else-if="currentTab === 2" 
                        :value="film.award_type || 0"
                        @change.stop="updateFilmAward(film.id, $event.target.value)"
                        @click.stop
                        class="status-select"
                      >
                        <option value="0">Not selected</option>
                        <option v-for="(quota, type) in awardQuotas" 
                                :key="type" 
                                :value="type"
                                :disabled="(statusCounts.award_counts?.[type] || 0) >= quota && film.award_type != type"
                        >
                          {{ getAwardLabel(type) }} ({{ statusCounts.award_counts?.[type] || 0 }}/{{ quota }})
                        </option>
                      </select>
                      <span v-else>{{ getStatusText(film.status) }}</span>
                    </td>
                    <td>{{ new Date(film.create_time).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-container">
              <div class="page-size-selector">
                <span>Number of lines per page:</span>
                <select v-model="pageSize" @change="handlePageSizeChange">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              
              <div class="page-info">
                From line {{ (currentPage - 1) * pageSize + 1 }} to line {{ Math.min(currentPage * pageSize, total) }}, a total of {{ total }} lines
              </div>
              
              <div class="page-buttons">
                <button 
                  class="btn btn-outline" 
                  :disabled="currentPage === 1"
                  @click="changePage(currentPage - 1)"
                >
                  <span class="arrow">←</span>
                </button>
                <button 
                  class="btn btn-outline"
                  :disabled="currentPage >= Math.ceil(total / pageSize)"
                  @click="changePage(currentPage + 1)"
                >
                  <span class="arrow">→</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 详情视图 -->
        <div v-else-if="currentView === 'detail'" class="content-card">
          <!-- Submitter Information Section -->
          <div class="section">
            <h2 class="section-title">Submitter Information</h2>
            <div class="table-wrapper">
              <table class="info-table">
                <thead>
                  <tr>
                    <th style="width: 15%">Name</th>
                    <th style="width: 10%">Gender</th>
                    <th style="width: 12%">Country</th>
                    <th style="width: 15%">Contact Number</th>
                    <th style="width: 15%">E-mail</th>
                    <th style="width: 33%">Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ selectedFilm.submitter_firstname }} {{ selectedFilm.submitter_lastname }}</td>
                    <td>{{ formatGender(selectedFilm.submitter_gender) }}</td>
                    <td>{{ selectedFilm.submitter_country }}</td>
                    <td>{{ selectedFilm.submitter_phone }}</td>
                    <td>{{ selectedFilm.submitter_email }}</td>
                    <td>
                      {{ selectedFilm.submitter_address }}<br>
                      {{ selectedFilm.submitter_address2 }}<br>
                      {{ selectedFilm.submitter_city }}, {{ selectedFilm.submitter_state }} {{ selectedFilm.submitter_postal_code }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Film Information Section -->
          <div class="section">
            <h2 class="section-title">Film Information</h2>
            <div class="table-wrapper">
              <table class="info-table">
                <thead>
                  <tr>
                    <th style="width: 25%">Poster</th>
                    <th style="width: 25%">Title</th>
                    <th style="width: 35%">Synopsis</th>
                    <th style="width: 15%">Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img 
                        v-if="selectedFilm.cover_image" 
                        :src="selectedFilm.cover_image" 
                        alt="Film Poster"
                        class="film-poster"
                      >
                    </td>
                    <td>
                      <div class="film-info-cell">
                        <div class="title">{{ selectedFilm.title }}</div>
                        <button 
                          v-if="selectedFilm.submission_url"
                          class="play-button" 
                          @click.stop="handlePlayVideo(selectedFilm.submission_url)"
                        >
                          Play Film
                        </button>
                        <div v-if="selectedFilm.submission_password" class="password-section">
                          <span class="password-label">Password:</span>
                          <span class="password-value">{{ selectedFilm.submission_password }}</span>
                          <button 
                            class="copy-button"
                            @click.stop="copyPassword(selectedFilm.submission_password)"
                          >
                            Copy
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>{{ selectedFilm.description }}</td>
                    <td>{{ formatDuration(selectedFilm.duration) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Creative Team Section -->
          <div class="section">
            <h2 class="section-title">Creative Team Information</h2>
            <div v-if="selectedFilm.other_crew" class="other-crew-info">
              {{ selectedFilm.other_crew }}
            </div>
            
            <!-- Directors Table -->
            <div class="team-section">
              <h3>Directors</h3>
              <div class="table-wrapper">
                <table class="info-table">
                  <thead>
                    <tr>
                      <th style="width: 20%">Photo</th>
                      <th style="width: 20%">Name</th>
                      <th style="width: 20%">English Name</th>
                      <th style="width: 40%">Introduction</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="director in selectedFilm.directors" :key="director.id">
                      <td>
                        <img 
                          v-if="director.photo_url" 
                          :src="director.photo_url" 
                          alt="Director Photo"
                          class="director-photo"
                        >
                      </td>
                      <td>{{ director.name }}</td>
                      <td>{{ director.name_en }}</td>
                      <td>{{ director.introduction }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Screenwriters Table -->
            <div class="team-section">
              <h3>Screenwriters</h3>
              <div class="table-wrapper">
                <table class="info-table">
                  <thead>
                    <tr>
                      <th style="width: 50%">Name</th>
                      <th style="width: 50%">English Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="writer in selectedFilm.screenwriters" :key="writer.id">
                      <td>{{ writer.name }}</td>
                      <td>{{ writer.name_en }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Actors Table -->
            <div class="team-section">
              <h3>Actors</h3>
              <div class="table-wrapper">
                <table class="info-table">
                  <thead>
                    <tr>
                      <th style="width: 50%">Name</th>
                      <th style="width: 50%">English Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="actor in selectedFilm.actors" :key="actor.id">
                      <td>{{ actor.name }}</td>
                      <td>{{ actor.name_en }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentTab === 1" class="bottom-actions">
        <div class="quota-info">
          Remaining shortlist quota: {{ 100 - (statusCounts[2] || 0) }}/100
        </div>
      </div>

      <div v-if="currentTab === 2" class="bottom-actions2">
        <button 
          class="btn btn-primary btn-active download-button"
          @click="handleDownloadAwardList"
        >
          Download the list of award
        </button>
      </div>
    </div>

    <!-- 登录过期提示框 -->
    <div v-if="showLoginExpiredModal" class="modal-overlay">
      <div class="modal-content">
        <h2>Login status is expired</h2>
        <p>Please login again</p>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="handleLoginExpired">Ok</button>
        </div>
      </div>
    </div>

    <!-- 指定评委模态框 -->
    <div v-if="showJudgeModal" class="modal-overlay">
      <div class="modal-content judge-modal">
        <div class="modal-header">
          <h2>Select Judges</h2>
          <button class="close-button" @click="closeJudgeModal">
            <img src="@/assets/close-button.png" alt="Close">
          </button>
        </div>
        
        <div class="search-box">
          <img src="@/assets/search-icon.png" alt="Search" class="search-icon">
          <input 
            type="text" 
            v-model="judgeSearchQuery" 
            placeholder="Search by name or account"
            class="search-input"
          >
        </div>

        <div class="info-text">
          <img src="@/assets/icon-msg.png" alt="Info Icon" class="info-icon">
          <span>There must be three judges selected during the preliminary review stage</span>
        </div>
        <div class="info-text">
          <img src="@/assets/icon-msg.png" alt="Info Icon" class="info-icon">
          <span>If you cancel a judge, the judge's score will be deleted</span>
        </div>
        <div class="judge-list">
          <div 
            v-for="judge in filteredJudges" 
            :key="judge.id"
            :class="['judge-option', { selected: selectedJudges.includes(judge.id) }]"
            @click="toggleJudge(judge.id)"
          >
            <div class="judge-content">
              <img 
                :src="getAvatarUrl(judge.avatar)"
                alt="Judge Avatar" 
                class="judge-avatar"
                @error="handleAvatarError"
              >
              <div class="judge-info">
                <div class="judge-name">{{ judge.nickname }}</div>
                <div class="judge-account">{{ judge.username }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-group">
            <button 
              class="btn btn-secondary" 
              @click="closeJudgeModal"
            >
              Cancel
            </button>
            <button 
              :class="['btn btn-primary', { 'btn-active': selectedJudges.length === 3 }]"
              @click="submitJudges"
              :disabled="selectedJudges.length !== 3"
            >
              Confirm ({{ selectedJudges.length }}/3)
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改用户信息弹窗 -->
    <div v-if="showUserModal" 
         class="modal-overlay"
         @click.self="showUserModal = false">
      <div class="modal-content user-modal">
        <!-- 第一行：用户信息 -->
        <div class="user-info-row">
          <div class="user-details">
            <div class="user-nickname">{{ userInfo.nickname }}</div>
            <div class="user-username">{{ userInfo.username }}</div>
          </div>
        </div>
        
        <!-- 第二行：Home按钮 -->
        <div class="action-row">
          <button class="btn-full btn-text" @click="showUserModal = false">
            Home
          </button>
        </div>
        
        <!-- 第三行：Logout按钮 -->
        <div class="action-row">
          <button class="btn-full btn-secondary" @click="handleLogout">
            Logout
          </button>
        </div>
      </div>
    </div>

    <!-- 批量指定评委确认弹窗 -->
    <div v-if="showBatchAssignModal" class="modal-overlay">
      <div class="modal-content batch-assign-modal">
        <div class="modal-header">
          <h2>Batch Assign Judges</h2>
          <button class="close-button" @click="closeBatchAssignModal">
            <img src="@/assets/close-button.png" alt="Close">
          </button>
        </div>
        
        <div class="modal-body">
          <div class="warning-message">
            <img src="@/assets/icon-msg.png" alt="Warning" class="warning-icon">
            <p>This operation will randomly assign three judges to all films that have not yet been assigned judges. Are you sure you want to continue?</p>
          </div>
        </div>

        <div class="modal-footer">
          <div class="button-group">
            <button 
              class="btn btn-secondary" 
              @click="closeBatchAssignModal"
            >
              Cancel
            </button>
            <button 
              class="btn btn-primary btn-active"
              @click="confirmBatchAssign"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加确认弹窗 -->
    <div v-if="showConfirmModal" class="modal-overlay">
      <div class="modal-content confirm-modal">
        <div class="modal-header">
          <h2>Confirmation</h2>
        </div>
        <div class="modal-body">
          <p>{{ confirmMessage }}</p>
        </div>
        <div class="modal-footer">
          <div class="button-group">
            <button 
              class="btn btn-secondary" 
              @click="handleConfirmCancel"
            >
              Cancel
            </button>
            <button 
              class="btn btn-primary btn-active"
              @click="handleConfirmOk"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { ElMessage, ElLoading } from 'element-plus'

export default {
  name: 'AdminFilmsPage',
  data() {
    return {
      films: [],
      currentTab: 1, // 默认显示待评审
      statusCounts: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      showLoginExpiredModal: false,
      showJudgeModal: false,
      selectedJudges: [],
      judges: [],
      tabs: [
        { label: 'Unreviewed', value: 1 },
        { label: 'Shortlisted', value: 2 },
        { label: 'Not shortlisted', value: 3 }
      ],
      currentFilmId: null,
      filmSearchQuery: '',  // 用于影片搜索
      judgeSearchQuery: '', // 用于评委搜索
      searchField: 'title', // Default search field
      searchFields: [
        { label: 'Work', value: 'title' },
        { label: 'Director', value: 'director' }
      ],
      markFilter: '', // Add this line
      markOptions: [
        { label: 'All', value: '' },
        { label: 'Recommended', value: '1' },
        { label: 'Not Recommended', value: '0' }
      ],
      currentView: 'list',  // 'list' or 'detail'
      selectedFilm: null,
      showUserModal: false,
      userInfo: {
        avatar: 'avatar-1.png',
        nickname: '',
        username: ''
      },
      showBatchAssignModal: false,
      awardQuotas: {
        1: 2, // Gold Award
        2: 5, // Silver Award
        3: 12, // Bronze Award
        4: 1, // Annual Touching
        5: 1, // Annual Tribute
        6: 1  // Annual Theme
      },
      showConfirmModal: false,
      confirmMessage: '',
      pendingAction: null,
      pendingParams: null,
    }
  },
  
  created() {
    this.fetchFilms()
    this.fetchUserInfo()

    // Remove popstate event listener setup
    //window.history.replaceState({ view: 'list' }, '')
  },

  beforeUnmount() {
    // Remove event listener cleanup
  },
  
  computed: {
    filteredJudges() {
      const query = this.judgeSearchQuery.toLowerCase().trim()
      if (!query) return this.judges
      
      return this.judges.filter(judge => 
        judge.nickname.toLowerCase().includes(query) ||
        judge.username.toLowerCase().includes(query)
      )
    }
  },
  
  methods: {
    switchTab(tabValue) {
      this.currentTab = tabValue
      this.currentPage = 1
      this.fetchFilms()
    },
    
    // 处理登录过期
    handleLoginExpired() {
      this.showLoginExpiredModal = false
      this.$router.push('/admin')
    },

    // 修改所有的 axios 请求，添加错误处理
    async fetchFilms() {
      try {
        const response = await axios.get('/admin/films', {
          params: {
            status: this.currentTab === 2 ? [2, 4] : this.currentTab,
            page: this.currentPage,
            limit: this.pageSize,
            search_field: this.searchField,
            search_query: this.filmSearchQuery,
            mark_filter: this.markFilter
          }
        })
        if (response.data.code === 200) {
          this.films = response.data.data.list
          this.total = response.data.data.total
          this.statusCounts = {
            ...response.data.data.counts,
            award_counts: response.data.data.award_counts
          }
          this.awardQuotas = response.data.data.award_quotas
        } else if (response.data.code === 401) {
          this.showLoginExpiredModal = true
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('获取影片列表失败:', error)
        }
      }
    },

    async fetchJudges() {
      try {
        const response = await axios.get('/admin/judges', {
          params: {
            round: this.currentTab === 1 ? 1 : 2
          }
        });
        if (response.data.code === 200) {
          this.judges = response.data.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('获取评委列表失败:', error)
        }
      }
    },

    async submitJudges() {
      if (this.selectedJudges.length !== 3) return;

      try {
        const response = await axios.post(
          `/admin/films/${this.currentFilmId}/judges`,
          {
            judge_ids: this.selectedJudges,
            round: this.currentTab === 1 ? 1 : 2
          }
        );
        
        if (response.data.code === 200) {
          this.closeJudgeModal();
          this.fetchFilms();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('指定评委失败:', error)
        }
      }
    },

    async updateFilmStatus(filmId, status) {
      if (status === "2") {
        this.showConfirmDialog(
          "Determine whether to conduct the shortlisting operation. This operation cannot be reversed in this system version.",
          'updateFilmStatus',
          { filmId, status }
        )
        return
      } else if (status === "3") {
        this.showConfirmDialog(
          "Determine whether to conduct the not chosen operation. This operation cannot be reversed in this system version.",
          'updateFilmStatus',
          { filmId, status }
        )
        return
      }
      await this.doUpdateFilmStatus(filmId, status)
    },

    async updateFilmAward(filmId, awardType) {
      if (awardType !== "0") {
        this.showConfirmDialog(
          "Determine whether to conduct the awarding operation. This operation cannot be reversed in this system version.",
          'updateFilmAward',
          { filmId, awardType }
        )
        return
      }
      await this.doUpdateFilmAward(filmId, awardType)
    },

    showConfirmDialog(message, action, params) {
      this.confirmMessage = message
      this.pendingAction = action
      this.pendingParams = params
      this.showConfirmModal = true
    },

    async handleConfirmOk() {
      this.showConfirmModal = false
      if (this.pendingAction === 'updateFilmStatus') {
        await this.doUpdateFilmStatus(
          this.pendingParams.filmId,
          this.pendingParams.status
        )
      } else if (this.pendingAction === 'updateFilmAward') {
        await this.doUpdateFilmAward(
          this.pendingParams.filmId,
          this.pendingParams.awardType
        )
      }
    },

    handleConfirmCancel() {
      this.showConfirmModal = false
      this.fetchFilms() // 重置选择
    },

    async doUpdateFilmStatus(filmId, status) {
      try {
        const response = await axios.put(
          `/admin/films/${filmId}/status`,
          { status }
        )
        
        if (response.data.code === 200) {
          this.fetchFilms()
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('更新状态失败:', error)
        }
      }
    },

    async doUpdateFilmAward(filmId, awardType) {
      try {
        const response = await axios.put(
          `/admin/films/${filmId}/award`,
          { award_type: awardType }
        )
        
        if (response.data.code === 200) {
          this.fetchFilms()
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('更新获奖状态失败:', error)
        }
      }
    },

    async assignJudges(filmId) {
      this.currentFilmId = filmId
      this.selectedJudges = []
      
      try {
        // 并行请求所有评委和已分配的评委
        const [judgesResponse, assignedJudgesResponse] = await Promise.all([
          axios.get('/admin/judges', {
            params: {
              round: this.currentTab === 1 ? 1 : 2  // 根据当前tab获取对应轮次的评委
            }
          }),
          axios.get(`/admin/films/${filmId}/judges`, {
            params: {
              round: this.currentTab === 1 ? 1 : 2  // 同样需要获取对应轮次的已分配评委
            }
          })
        ])
        
        if (judgesResponse.data.code === 200) {
          this.judges = judgesResponse.data.data
        }
        
        if (assignedJudgesResponse.data.code === 200) {
          // 设置已分配的评委为选中状态
          this.selectedJudges = assignedJudgesResponse.data.data.map(judge => judge.id)
        }
        
        this.showJudgeModal = true
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showLoginExpiredModal = true
        } else {
          console.error('Failed to get judge list:', error)
        }
      }
    },

    getCurrentRoundMarks(film) {
      return film.judge_marks.filter(mark => {
        // For Unreviewed tab (round 1), show preliminary judges' marks
        if (this.currentTab === 1) {
          return mark.judge_type === 1;
        }
        // For Shortlisted tab (round 2), show final judges' marks
        else if (this.currentTab === 2) {
          return mark.judge_type === 2;
        }
        return true;
      });
    },

    getCurrentRoundScores(film) {
      return film.judge_scores.filter(score => {
        // For Unreviewed tab (round 1), show preliminary judges' scores
        if (this.currentTab === 1) {
          return score.judge_type === 1;
        }
        // For Shortlisted tab (round 2), show final judges' scores
        else if (this.currentTab === 2) {
          return score.judge_type === 2;
        }
        return true;
      });
    },

    hasAssignedJudges(film) {
      return film.judge_marks && film.judge_marks.length > 0;
    },

    getMarkClass(mark) {
      return {
        'mark-recommended': mark === 'Recommended',
        'mark-not-recommended': mark === 'Not considered',
        'mark-unreviewed': mark === 'Unreviewed'
      }
    },

    toggleJudge(judgeId) {
      const index = this.selectedJudges.indexOf(judgeId)
      if (index === -1 && this.selectedJudges.length < 3) {
        this.selectedJudges.push(judgeId)
      } else if (index !== -1) {
        this.selectedJudges.splice(index, 1)
      }
    },

    closeJudgeModal() {
      this.showJudgeModal = false
      this.selectedJudges = []
      this.currentFilmId = null
    },

    handlePageSizeChange() {
      this.currentPage = 1;
      this.fetchFilms();
    },
    
    changePage(page) {
      this.currentPage = page;
      this.fetchFilms();
    },

    async handleSearch() {
      this.currentPage = 1;
      await this.fetchFilms();
    },

    formatDuration(seconds) {
      if (!seconds) return '--';
      
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      
      const parts = [];
      
      if (hours > 0) {
        parts.push(hours.toString().padStart(2, '0'));
      }
      
      parts.push(minutes.toString().padStart(2, '0'));
      parts.push(remainingSeconds.toString().padStart(2, '0'));
      
      return parts.join(':');
    },

    async showFilmDetail(film) {
      try {
        const response = await axios.get(`/admin/films/${film.id}`)
        if (response.data.code === 200) {
          this.selectedFilm = response.data.data
          this.currentView = 'detail'
          // Remove browser history manipulation
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error('Failed to fetch film detail:', error)
      }
    },

    async handlePlayVideo(submissionUrl) {
      if (submissionUrl.startsWith('oss://')) {
        try {
          const response = await axios.get(`/admin/films/${this.selectedFilm.id}/stream`)
          if (response.data.code === 200) {
            window.open(response.data.data.url, '_blank')
          } else {
            ElMessage.error(response.data.msg || 'Failed to get video URL')
          }
        } catch (error) {
          console.error('Failed to get video URL:', error)
          ElMessage.error('Failed to get video URL')
        }
      } else if (submissionUrl.startsWith('http://') || submissionUrl.startsWith('https://')) {
        // 转换 '+' 为 '%2B'
        const formattedUrl = submissionUrl.replace(/\+/g, '%2B');
        window.open(formattedUrl, '_blank');
      }
    },

    copyPassword(password) {
      navigator.clipboard.writeText(password)
        .then(() => {
          ElMessage.success('Password copied to clipboard')
        })
        .catch(() => {
          ElMessage.error('Failed to copy password')
        })
    },

    async fetchUserInfo() {
      try {
        const response = await axios.get('/admin/user/info')
        if (response.data.code === 200) {
          if (response.data.data) {
            this.userInfo = response.data.data
          }
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      }
    },
    
    handleLogout() {
      localStorage.removeItem('token')
      this.$router.push('/admin')
    },

    handleBatchAssign() {
      this.showBatchAssignModal = true
    },

    closeBatchAssignModal() {
      this.showBatchAssignModal = false
    },

    async confirmBatchAssign() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Assigning judges, this may take a few minutes...',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      try {
        const response = await axios.post('/admin/films/batch-assign-judges', {
          round: this.currentTab === 1 ? 1 : 2
        }, {
          timeout: 300000  // 设置为5分钟
        })

        if (response.data.code === 200) {
          ElMessage.success('Judges assigned successfully')
          this.fetchFilms()
        } else {
          ElMessage.error(response.data.msg || 'Failed to assign judges')
        }
      } catch (error) {
        if (error.response?.status === 401) {
          this.showLoginExpiredModal = true
        } else if (error.code === 'ECONNABORTED') {
          ElMessage.error('Operation timed out. Please try again.')
        } else {
          ElMessage.error('Failed to assign judges')
          console.error('批量分配评委失败:', error)
        }
      } finally {
        // 关闭加载提示
        loading.close()
        this.closeBatchAssignModal()
      }
    },

    formatGender(gender) {
      return gender === 1 ? 'Male' : gender === 2 ? 'Female' : '--'
    },

    getAwardLabel(type) {
      const labels = {
        1: 'Gold Award',
        2: 'Silver Award',
        3: 'Bronze Award',
        4: 'Annual Touching',
        5: 'Annual Tribute',
        6: 'Annual Theme'
      }
      return labels[type] || ''
    },

    getStatusText(status) {
      const statusMap = {
        1: 'Unreviewed',
        2: 'Shortlisted',
        3: 'Not chosen',
        4: 'Awarded'
      }
      return statusMap[status] || 'Unknown'
    },

    async handleDownloadAwardList() {
      try {
        const response = await axios.get('/admin/films/export', {
          responseType: 'blob'  // Important for handling file download
        });
        
        // Create a blob URL and trigger download
        const blob = new Blob([response.data], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `award_list_${new Date().toISOString().split('T')[0]}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        ElMessage.error('Failed to download award list');
        console.error('Download failed:', error);
      }
    },

    getAvatarUrl(avatar) {
      return require(`@/assets/avatar/${avatar}`)
    },

    handleAvatarError(e) {
      e.target.src = require('@/assets/avatar/avatar-1.png')
    },

    getDirectorNames(film) {
      // 首选使用 director_names
      if (film.director_names && film.director_names.length > 0) {
        return film.director_names.join(', ');
      }
      
      // 如果 director_names 为空，尝试从 other_crew 提取
      if (film.other_crew) {
        // 同时处理中英文逗号的情况
        const firstPart = film.other_crew.split(/[,，]/)[0];
        return firstPart.trim();
      }
      
      return '';  // 如果都没有则返回空字符串
    }
  }
}
</script>

<style scoped>
.admin-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 120px;
  background-color: #000;
  color: white;
  padding: 0rem 0;
}

.logo {
  padding: 0rem;
  text-align: center;
  cursor: pointer; /* 添加手型光标 */
  transition: opacity 0.3s; /* 添加过渡效果 */
}

.logo:hover {
  opacity: 0.8; /* 添加悬停效果 */
}

.logo img {
  max-width: 100px;
}

.nav-tabs {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.nav-item {
  padding: 1rem;
  text-decoration: none;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  /* 设置渐变背景 */
  background: grey;
  /* 将背景裁剪到文字 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设为透明,这样就能看到背景 */
  color: transparent;
}

.nav-item.active {
  /* 设置渐变背景 */
  background: linear-gradient(to bottom, #FEF3D5, #FFF1D4, #FDD68D);
  /* 将背景裁剪到文字 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设为透明,这样就能看到背景 */
  color: transparent;
}

.nav-item:hover{
  background-color: white;
}

.nav-item img.nav-icon {
  filter: grayscale(100%); /* 图标颜色为灰色 */
}

.nav-item.active img.nav-icon {
  filter: none; /* 选中时图标颜色恢复 */
}

.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #f5f5f5;
}

.page-title {
  text-align: left;
  margin-bottom: 0;
  font-size: 1.8rem;
}

.content-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.view-tabs {
  padding: 1rem 0 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
}

.view-tab {
  padding: 0.8rem 1.5rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
  margin-right: 1rem;
  position: relative;
  top: 1px;
}

.view-tab:hover {
  color: black;
}

.view-tab.active {
  color: black;
  border-bottom-color: black;
}

.content-card {
  padding: 0rem;
  background: transparent;
  box-shadow: none;
  text-align: left;
}

.bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
}

.bottom-actions2 {
  display: flex;
  justify-content: flex-end;
  align-items: right;
  padding: 1rem 0;
  width: 100%;
}

.quota-info {
  color: red;
  font-size: 0.9rem;
  text-align: left;
}

.download-button {
  width: auto;  /* 让按钮宽度由内容决定 */
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
}


.films-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  padding: 1rem;
  text-align: left;
  background-color: #f5f5f5;
  font-weight: normal;
}

th:nth-child(1) {
  width: 20%;
}

th:nth-child(2) {
  width: 10%;
}

th:nth-child(3) {
  width: 16%;
}

th:nth-child(4) {
  width: 14%;
}

th:nth-child(5) {
  width: 11%;
}

th:nth-child(6) {
  width: 12%;
}

th:nth-child(7) {
  width: 15%;
}

td {
  max-width: 0;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}


.btn-primary:hover {
  background-color: lightgrey;
}

.btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-outline {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-outline:disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.btn-secondary {
  background-color: #666;
  color: white;
}

.btn-small {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
}

/* 登录过期弹窗样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 2rem;
}

.user-modal {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
}

.user-info-row {
  border-bottom: 1px solid #eee;
}

.user-details {
  text-align: left;
  padding: 0.5rem;
}

.user-nickname {
  font-size: 1rem;
  font-weight: 500;
}

.user-username {
  color: #666;
  font-size: 0.875rem;
}

.action-row {
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #eee;
}

.action-row:last-child {
  border-bottom: none;
}

.btn-full {
  width: 100%;
  padding: 0.5rem;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
}

.btn-text {
  color: #333;
}

.btn-text:hover {
  background-color: #f5f5f5;
}

.btn-secondary {
  color: #ff4d4f;
}

.btn-secondary:hover {
  background-color: #fff1f0;
}

.close-button {
  display: none; /* 隐藏关闭按钮 */
}

.count-badge {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  background-color: #000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.judge-item {
  margin: 4px 0;
}

.mark-tag {
  display: inline-block;
  width: 86px; /* 172px ÷ 2 */
  height: 28px; /* 56px ÷ 2 */
  line-height: 28px; /* 设置行高等于新的高度 */
  font-size: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* 确保背景图片保持原始比例 */
  text-align: center;
  color: grey;
}

.mark-recommended {
  background-image: url('@/assets/recommend_bg.png');
  color: var(--primary-color);
}

.mark-not-recommended {
  border: 1px solid #FE007F;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 0px;    /* 移除上下内边距，只保留左右内边距 */
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
  color: #FE007F;
}

.mark-tag.mark-unreviewed {
  border: 1px solid grey;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 12px;    /* 移除上下内边距，只保留左右内边距 */
  color: grey;
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
}



.score-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star-icon {
  width: 18px;
  height: 18px;
}

.judge-modal {
  max-width: 600px;
  width: 90%;
}

.judge-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem 0;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
}

.judge-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.judge-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.judge-option {
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.judge-option:hover {
  border-color: lightgrey;
  background-color: #f9f9f9;
}

.judge-option.selected {
  background-color: lightgrey;
  border-color: lightgrey;
}

.judge-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.judge-name {
  font-weight: 500;
  font-size: 1rem;
}

.judge-account {
  color: #666;
  font-size: 0.9rem;
}

.judge-option.selected {
  font-weight: 500;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
}


.nav-icon {
  width: 20px;
  height: 20px;
}

.assigned-text {
  color: #333;
  cursor: default;
}

.clickable-text {
  color: red;
  cursor: pointer;
  text-decoration: none;
}

.clickable-text:hover {
  text-decoration: underline;
}

.info-text {
  display: flex;
  align-items: left;
  gap: 4px;  /* 图标和文字之间的间距 */
  font-size: 0.8rem;
}

.info-icon {
  width: 16px;
  height: 16px;
}

.search-box {
  position: relative;
  margin: 1rem 0;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #000;
}

.search-input::placeholder {
  color: #999;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border: 1px dashed #000;
  border-radius: 4px;
  background: transparent;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.close-button:hover {
  border-color: #666;
}

.close-button img {
  width: 100%;
  height: 100%;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.page-size-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-size-selector select {
  padding: 0.3rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.page-info {
  color: #666;
}

.page-buttons {
  display: flex;
  gap: 0.5rem;
}

.page-buttons .btn {
  padding: 0.3rem 0.8rem;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
}

.page-buttons .btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.arrow {
  font-size: 1.2rem;
  line-height: 1;
}

.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.search-box {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  min-width: 300px;
}

.search-field-select {
  position: relative;
  min-width: 120px;
}

.search-field-select select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px; /* 右侧留出箭头空间 */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.search-field-select::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none; /* 确保箭头不会影响选择 */
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .search-field-select select {
      padding-right: 32px; /* Safari 中确保有足够空间显示箭头 */
    }
  }
}

/* 悬停和焦点状态 */
.search-field-select select:hover {
  border-color: #999;
}

.search-field-select select:focus {
  outline: none;
  border-color: #666;
}

.search-input-wrapper {
  display: flex;
  flex: 1;
  gap: 0.5rem;
}

.search-input-wrapper input {
  flex: 1;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.search-button:hover {
  opacity: 0.9;
}

.filter-dropdown {
  position: relative;
  width: 200px;
}

.filter-title {
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  background-color: white;
  color: #666;
  z-index: 1;
}

.filter-select-wrapper {
  position: relative;
}

.filter-select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.filter-select-wrapper::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .filter-select-wrapper select {
      padding-right: 32px;
    }
  }
}

/* 悬停和焦点状态 */
.filter-select-wrapper select:hover {
  border-color: #999;
}

.filter-select-wrapper select:focus {
  outline: none;
  border-color: #666;
}

.title-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.film-title {
  font-weight: 420;
  color: red;
}

.film-directors {
  font-size: 0.8em;
  color: #666;
}

.film-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.film-row:hover {
  background-color: #f5f5f5;
}

.detail-container {
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.section-title {
  font-size: 1.2rem;
  margin: 1rem;
  color: #333;
  text-align: left;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item label {
  font-size: 0.9rem;
  color: #666;
}

.description {
  white-space: pre-line;
  line-height: 1.5;
}

.creators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.creator-group h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

.creator-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.creator-item {
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
  font-size: 0.9rem;
}

.back-button:hover {
  color: #333;
}

.back-icon {
  width: 16px;
  height: 16px;
}

.page-title {
  margin: 0;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.table-wrapper {
  overflow-x: auto;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 0px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.film-poster {
  width: 120px;     /* 增加基础宽度 */
  height: 180px;    /* 保持 2:3 的宽高比 */
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 1;       /* 确保悬停时图片显示在上层 */
}
/* 调整表格列宽以适应更大的海报 */
.info-table th:nth-child(1) {  /* Poster 列 */
  width: 18%;              /* 略微增加列宽 */
}

.info-table th:nth-child(2) {  /* Title 列 */
  width: 27%;              /* 相应调整 */
}

.info-table th:nth-child(3) {  /* Synopsis 列 */
  width: 40%;              /* 保持不变 */
}

.info-table th:nth-child(4) {  /* Duration 列 */
  width: 15%;              /* 保持不变 */
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.team-group h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #666;
}

.member-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.member-item {
  padding: 0.5rem 1rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
}

.film-info-cell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.film-info-cell .title {
  font-weight: 500;
}

.play-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}

.play-button:hover {
  background-color: #333;
}

.password-section {
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.password-label {
  color: #666;
}

.password-value {
  font-family: monospace;
  background: #f5f5f5;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  color: red;  /* Add red color */
  font-size: 1.1rem;  /* Increase font size */
  font-weight: 500;  /* Add slightly bolder font weight */
}

.copy-button {
  background: none;
  border: 1px solid #ddd;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.3s;
}

.copy-button:hover {
  border-color: #000;
  background: #f5f5f5;
}

.team-section {
  margin-bottom: 2rem;
}

.team-section:last-child {
  margin-bottom: 0;
}

.team-section h3 {
  font-size: 1.2rem;
  margin: 1rem 1rem;
  color: #333;
}

.director-photo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  /* 改为顶部对齐 */
  margin-bottom: 2rem;
  width: 100%;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;  /* 标题和返回按钮之间的间距 */
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.user-avatar:hover {
  opacity: 0.8;
}


.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.user-avatar-large {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.user-nickname {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.user-username {
  color: #666;
  font-size: 0.9rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button img {
  width: 16px;
  height: 16px;
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 1rem 0rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}


.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.btn-secondary:hover {
  background-color: #f5f5f5;
}

.btn-primary {
  background-color: #ddd;
  border: 1px solid #ddd;
  color: #fff;
  min-width: 160px;
}

.btn-primary.btn-active {
  background-color: #000;
  border: 1px solid #000;
}

.btn-primary:disabled {
  background-color: #ddd;
  border: 1px solid #ddd;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):hover {
  opacity: 0.9;
}

/* 添加批量指定评委按钮样式 */
.batch-assign-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.batch-assign-button:hover {
  opacity: 0.9;
}

/* 调整搜索过滤区域的样式以适应新按钮 */
.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.search-box {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  min-width: 300px;
}

.search-field-select select {
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 120px;
}

.search-input-wrapper {
  display: flex;
  flex: 1;
  gap: 0.5rem;
}

.search-input-wrapper input {
  flex: 1;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.search-button:hover {
  opacity: 0.9;
}

.filter-dropdown {
  position: relative;
  width: 200px;
}

.filter-title {
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  background-color: white;
  color: #666;
  z-index: 1;
}

.filter-select-wrapper {
  position: relative;
}

.filter-select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.filter-select-wrapper::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .filter-select-wrapper select {
      padding-right: 32px;
    }
  }
}

/* 悬停和焦点状态 */
.filter-select-wrapper select:hover {
  border-color: #999;
}

.filter-select-wrapper select:focus {
  outline: none;
  border-color: #666;
}

.detail-container {
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.table-wrapper {
  overflow-x: auto;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  /* 改为顶部对齐 */
  margin-bottom: 2rem;
  width: 100%;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;  /* 标题和返回按钮之间的间距 */
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.user-avatar:hover {
  opacity: 0.8;
}


.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.user-avatar-large {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.user-nickname {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.user-username {
  color: #666;
  font-size: 0.9rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button img {
  width: 16px;
  height: 16px;
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 1rem 0rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}


.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.btn-secondary:hover {
  background-color: #f5f5f5;
}

.btn-primary {
  background-color: #ddd;
  border: 1px solid #ddd;
  color: #fff;
  min-width: 160px;
}

.btn-primary.btn-active {
  background-color: #000;
  border: 1px solid #000;
}

.btn-primary:disabled {
  background-color: #ddd;
  border: 1px solid #ddd;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):hover {
  opacity: 0.9;
}

/* 批量指定评委确认弹窗 */
.batch-assign-modal {
  max-width: 500px;
  width: 90%;
}

.warning-message {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}

.warning-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.warning-message p {
  margin: 0;
  line-height: 1.5;
  color: #666;
  text-align: left;
}

.modal-body {
  padding: 1rem 0;
}

.status-select {
  padding: 4px 12px;
  height: 48px;
  border-radius: 10px;  /* 圆角边框 */
  border: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  appearance: none;  /* 移除默认的下拉箭头 */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('@/assets/dropdown-arrow.png');  /* 自定义下拉箭头 */
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
  padding-right: 28px;  /* 为箭头留出空间 */
  transition: border-color 0.3s;
}

.status-select:hover {
  border-color: #999;
}

.status-select:focus {
  outline: none;
  border-color: #666;
}

/* 移除 Firefox 中的下拉箭头 */
.status-select::-moz-focus-inner {
  border: 0;
}

/* 移除 IE 中的下拉箭头 */
.status-select::-ms-expand {
  display: none;
}

.confirm-modal {
  max-width: 500px;
  width: 90%;
}

.confirm-modal .modal-body {
  padding: 1.5rem 0;
  text-align: left;
}

.confirm-modal .modal-body p {
  margin: 0;
  line-height: 1.5;
  color: #666;
}

.other-crew-info {
  margin: 0 1rem 1.5rem;
  line-height: 1.5;
  color: #333;
  text-align: left;
  white-space: pre-wrap;
}
</style>
