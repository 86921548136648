import CryptoJS from 'crypto-js';

// Get secret key from environment variables or use default
const DEFAULT_SECRET_KEY = 'SMALLRIG_AWARDS_5202';
const SECRET_KEY = process.env.VUE_APP_SIGNATURE_SECRET_KEY || DEFAULT_SECRET_KEY;

// Log a warning if using default key in production
if (process.env.NODE_ENV === 'production' && !process.env.VUE_APP_SIGNATURE_SECRET_KEY) {
  console.warn(
    'Warning: Using default signature secret key. ' +
    'Set VUE_APP_SIGNATURE_SECRET_KEY in your production environment variables.'
  );
}

/**
 * Generate a secure signature for API requests
 * @param {Object} params - Request parameters object (must include film_id)
 * @returns {Object} - Original params with added signature and timestamp
 */
export function signRequest(params) {
  // Create a copy of the params to avoid modifying the original
  const signedParams = { ...params };
  
  // Add timestamp to prevent replay attacks
  signedParams.timestamp = Date.now().toString();
  
  // Get film_id from params
  const filmId = signedParams.film_id;
  
  if (!filmId) {
    console.error('Error: film_id is required for signature generation');
    return signedParams;
  }
  
  // Generate signature using HMAC-SHA256
  const dataToSign = `film_id=${filmId}&timestamp=${signedParams.timestamp}`;
  const signature = CryptoJS.HmacSHA256(dataToSign, SECRET_KEY).toString();
  
  signedParams.signature = signature;
  
  // Return params with signature
  return signedParams;
}

// Export for testing purposes
export { SECRET_KEY }; 