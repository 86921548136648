<template>
  <div class="judges-recommendations">
    <div class="judges-grid">
      <div 
        v-for="judge in judges" 
        :key="judge.id" 
        class="judge-card"
        :style="{ backgroundImage: `url(${require('@/assets/judge_pic/' + judge.judge_pic)})` }"
        @click="goToJudge(judge.id)"
      >
        <div class="judge-card-overlay">
          <div class="judge-info">
            <button class="play-button" @click="goToJudge(judge.id)">
              <span class="play-icon">▶</span>
            </button>
            <h3 class="judge-name">{{ judge.name_en }} Recommended</h3>
            <p class="judge-comment">{{ judge.recommendation }}</p>
            <div class="recommendation-count">
              {{ judge.recommended_count }} films
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
  name: 'RecommendationFilms',
  data() {
    return {
      judges: []
    }
  },
  mounted() {
    this.fetchJudges()
  },
  methods: {
    async fetchJudges() {
      try {
        const response = await axios.get('/public/judges/recommendations')
        if (response.data.code === 200) {
          this.judges = response.data.data.judges
        }
      } catch (error) {
        console.error('Failed to fetch judges:', error)
      }
    },
    getFirstValidCoverImage(films) {
      const defaultCover = require('@/assets/default-cover.png')
      if (!films || films.length === 0) return defaultCover
      const validFilm = films.find(film => film.cover_image)
      return validFilm ? validFilm.cover_image : defaultCover
    },
    goToJudge(judgeId) {
      this.$router.push(`/judge/${judgeId}`)
    }
  }
}
</script>

<style scoped>
.judges-recommendations {
  padding: 0rem;
}

.judges-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2px;
  padding: 0rem;
  max-width: 800px;
  margin: 0 auto;
}

.judge-card {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 300px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: none;
  border-bottom: 1px solid white;
}

.judge-card:hover {
  transform: translateY(-5px);
}

.judge-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.judge-info {
  text-align: left;
  color: white;
  padding: 20px;
  width: 100%;
}

.judge-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: white;
}

.recommendation-count {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.judge-comment {
  color: white;
  margin-bottom: 1rem;
  font-style: italic;
}

.play-button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid white;
  background: transparent;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 25px;
  transition: all 0.3s ease;
}

.play-button:hover {
  background: white;
  color: black;
}

.play-icon {
  font-size: 20px;
  margin-left: 3px;
}
</style> 