import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '@/components/LoginPage.vue'
import AdminFilmsPage from '@/views/admin/FilmsPage.vue'
import JudgeFilmsPage from '@/views/judge/FilmsPage.vue'
import FilmDetailPage from '@/components/FilmDetailPage.vue'
import JudgeRecommendations from '@/components/JudgeRecommendations.vue'
import AccountsPage from '@/views/admin/AccountsPage.vue'
import ShortlistedFilmsPage from '@/views/ShortlistedFilmsPage.vue'
import RecommendationFilmsPage from '@/views/RecommendationFilmsPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/shortlisted'
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: LoginPage,
    props: {
      defaultRole: 'admin'
    }
  },
  {
    path: '/judge',
    name: 'JudgeLogin',
    component: LoginPage,
    props: {
      defaultRole: 'judge'
    }
  },
  {
    path: '/admin/films',
    name: 'AdminFilms',
    component: AdminFilmsPage,
    meta: {
      requiresAuth: true,
      role: 'admin'
    }
  },
  {
    path: '/judge/films',
    name: 'JudgeFilms',
    component: JudgeFilmsPage,
    meta: {
      requiresAuth: true,
      role: 'judge'
    }
  },
  {
    path: '/film/:id',
    name: 'FilmDetail',
    component: FilmDetailPage
  },
  {
    path: '/judge/:id',
    name: 'JudgeRecommendations',
    component: JudgeRecommendations,
    props: true
  },
  {
    path: '/admin/accounts',
    name: 'AdminAccounts',
    component: AccountsPage,
    meta: { requiresAuth: true, role: 1 }
  },
  {
    path: '/shortlisted',
    name: 'ShortlistedFilms',
    component: ShortlistedFilmsPage
  },
  {
    path: '/recommend',
    name: 'RecommendationFilms',
    component: RecommendationFilmsPage
  }
  // ... other routes remain the same
]

// ... rest of the router configuration remains the same

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires admin access
  if (to.path.startsWith('/admin/')) {
    const token = localStorage.getItem('token')
    const userRole = localStorage.getItem('userRole')
    
    if (!token || userRole !== '1') {
      next('/')
      return
    }
  }
  
  // For judge routes, but exclude public judge recommendation pages
  if (to.path.startsWith('/judge/') && !to.name === 'JudgeRecommendations') {
    const token = localStorage.getItem('token')
    const userRole = localStorage.getItem('userRole')
    
    if (!token || userRole !== '2') {
      next('/')
      return
    }
  }
  
  next()
})

export default router