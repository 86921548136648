import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'
// 导入 Element Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 导入图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 添加错误处理
app.config.errorHandler = (err, vm, info) => {
  console.error('Global error:', err)
  console.error('Error info:', info)
  console.error('Component:', vm)
}

app.config.warnHandler = (msg, vm, trace) => {
  console.warn('Warning:', msg)
  console.warn('Trace:', trace)
}

app.use(router)
app.use(ElementPlus) // 使用 Element Plus


app.mount('#app')

