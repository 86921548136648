<template>
  <div class="shortlisted-films" 
       @touchstart="handleTouchStart" 
       @touchmove="handleTouchMove" 
       @touchend="handleTouchEnd">
    <div class="films-grid">
      <div 
        v-for="film in films" 
        :key="film.id" 
        class="film-card"
        :style="{ backgroundImage: `url(${film.cover_image || require('@/assets/default-cover.png')})` }"
      >
        <div class="film-overlay">
          <div class="film-info">
            <div class="film-category">{{ film.genre }}</div>
            <h3 class="film-title">{{ film.title }}</h3>
            <p class="film-description">{{ getFirstSentence(film.description) }}</p>
            <button class="play-button" @click="goToDetail(film.id)">
              <span class="play-icon">▶</span>
            </button>
          </div>
        </div>
      </div>
      <div class="pull-refresh-indicator" 
           :class="{ 'visible': isPulling }"
           @click="handleRefreshClick">
        <div class="refresh-content">
          <span class="arrow-icon" :class="{ 'rotate': true }">↑</span>
          <span class="refresh-text">{{ isMobile ? pullText : 'Click to refresh' }}</span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
  name: 'ShortlistedFilms',
  data() {
    return {
      films: [],
      touchStartY: 0,
      isPulling: false,
      pullText: 'Pull to refresh',
      pullThreshold: 60,
      r: this.getStoredRandomSeed(), // 从localStorage获取r参数
      isMobile: this.checkIsMobile(),
    }
  },
  mounted() {
    this.fetchFilms()
    this.updateRandomSeed() // 启动定时更新r参数
  },
  methods: {
    generateRandomSeed() {
      return Math.floor(Math.random() * 10000); // 生成一个随机数作为参数r
    },
    getStoredRandomSeed() {
      const storedR = localStorage.getItem('randomSeed');
      const timestamp = localStorage.getItem('randomSeedTimestamp');
      const now = Date.now();

      // 检查是否存在有效的r参数
      if (storedR && timestamp && (now - timestamp < 10 * 60 * 1000)) {
        return parseInt(storedR, 10); // 返回存储的r参数
      } else {
        const newR = this.generateRandomSeed();
        localStorage.setItem('randomSeed', newR);
        localStorage.setItem('randomSeedTimestamp', now);
        return newR; // 返回新生成的r参数
      }
    },
    async fetchFilms() {
      try {
        const response = await axios.get('/public/films/shortlisted', {
          params: {
            r: this.r // 使用当前的r参数
          }
        });
        if (response.data.code === 200) {
          this.films = response.data.data;
        }
      } catch (error) {
        console.error('Failed to fetch films:', error);
      }
    },
    getFirstSentence(text) {
      if (!text) return ''
      const sentence = text.split(/[.!?]/)
      return sentence[0] + (sentence.length > 1 ? '.' : '')
    },
    goToDetail(filmId) {
      this.$router.push(`/film/${filmId}`)
    },
    handleTouchStart(e) {
      // 检查是否滚动到底部
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10;
      if (isAtBottom) {
        this.touchStartY = e.touches[0].clientY;
      }
    },
    handleTouchMove(e) {
      if (!this.touchStartY) return;
      
      const touchY = e.touches[0].clientY;
      const pull = this.touchStartY - touchY; // 修改为向上拉动的距离计算
      
      if (pull > 0) {
        e.preventDefault();
        this.isPulling = true;
        this.pullText = pull > this.pullThreshold ? 'Release to refresh' : 'Pull to refresh';
      }
    },
    async handleTouchEnd(event) {
      if (!this.touchStartY) return;
      
      const touchY = event.changedTouches[0].clientY;
      const pull = this.touchStartY - touchY;
      
      if (pull > this.pullThreshold) {
        this.pullText = 'Refreshing...';
        this.r = this.generateRandomSeed();
        localStorage.setItem('randomSeed', this.r);
        localStorage.setItem('randomSeedTimestamp', Date.now());
        await this.fetchFilms();
        
        // 添加平滑滚动到顶部
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      
      this.touchStartY = 0;
      this.isPulling = false;
      this.pullText = 'Pull to refresh';
    },
    updateRandomSeed() {
      setInterval(() => {
        this.r = this.generateRandomSeed(); // 每10分钟更新r参数
        localStorage.setItem('randomSeed', this.r); // 更新localStorage中的r参数
        localStorage.setItem('randomSeedTimestamp', Date.now()); // 更新时间戳
      }, 10 * 60 * 1000);
    },
    checkIsMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    async handleRefreshClick() {
      if (this.isMobile) return; // 移动端不处理点击事件
      
      this.isPulling = true;
      this.r = this.generateRandomSeed();
      localStorage.setItem('randomSeed', this.r);
      localStorage.setItem('randomSeedTimestamp', Date.now());
      await this.fetchFilms();

      window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      
      setTimeout(() => {
        this.isPulling = false;
      }, 1000);
    }
  }
}
</script>

<style scoped>
.films-grid {
  display: grid;
  grid-template-columns: 1fr;  /* 修改为单列 */
  gap: 2px;
  padding: 0px;
  max-width: 800px;  /* 添加最大宽度限制 */
  margin: 0 auto;    /* 居中显示 */
}

.film-card {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  border: none;
  border-bottom: 1px solid white;
}

.film-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.film-card:hover .film-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.film-info {
  text-align: center;
  color: white;
  padding: 20px;
  max-width: 80%;
}

.film-category {
  font-size: 0.9rem;
  opacity: 0.8;
  margin-bottom: 10px;
}

.film-title {
  font-size: 1.5rem;
  margin: 10px 0;
}

.film-description {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.play-button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid white;
  background: transparent;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.play-button:hover {
  background: white;
  color: black;
}

.play-icon {
  font-size: 20px;
  margin-left: 3px;
}

.pull-refresh-indicator {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 2px;
  cursor: pointer;
}

.pull-refresh-indicator:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.refresh-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.arrow-icon {
  font-size: 24px;
  color: white;
  transition: transform 0.3s ease;
}

.arrow-icon.rotate {
  transform: rotate(180deg);
}

.refresh-text {
  color: white;
  font-size: 14px;
}
</style> 