<template>
  <div class="admin-layout">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="logo">
        <img :src="require('@/assets/project-logo.png')" alt="Project Logo">
      </div>
      <nav class="nav-tabs">
        <router-link to="/admin/films" class="nav-item" :class="{ active: $route.path === '/admin/films' }">
          <img src="@/assets/tab-icon1.png" alt="Judge Icon" class="nav-icon">
          <span>Judge Management</span>
        </router-link>
        <router-link to="/admin/accounts" class="nav-item" :class="{ active: $route.path === '/admin/accounts' }">
          <img src="@/assets/tab-icon3.png" alt="Account Icon" class="nav-icon">
          <span>Account Management</span>
        </router-link>
      </nav>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <div class="header-section">
        <div class="title-section">
          <h1 class="page-title">Judges and Staff Management</h1>
        </div>
        <div class="user-profile">
          <img 
            :src="require(`@/assets/avatar/${userInfo.avatar}`)" 
            alt="User Avatar" 
            class="user-avatar"
            @click="showUserModal = true"
          >
        </div>
      </div>

      <div class="content-card">
        <div class="films-table">
          <table>
            <thead>
              <tr>
                <th>Number</th>
                <th>Account Type</th>
                <th>Account Name</th>
                <th>Account Password</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="user.id">
                <td>{{ index + 1 }}</td>
                <td>
                  <span :class="{'role-label-organizer': user.role === 1, 'role-label-judge': user.role !== 1}">
                    {{ user.role === 1 ? 'Organizer' : 'Judge' }}
                  </span>
                </td>
                <td>{{ user.nickname }}</td>
                <td>********</td>
                <td>
                  <button 
                    class="btn btn-primary btn-small"
                    @click="handleChangePassword(user.id)"
                  >
                    Change PW
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Change Password Modal -->
    <div v-if="showPasswordModal" class="modal-overlay">
      <div class="modal-content password-modal">
        <div class="modal-header">
          <h2>Modify Account Password</h2>
          <button class="close-button" @click="closePasswordModal">
            <img src="@/assets/close-button.png" alt="Close">
          </button>
        </div>
        <div class="modal-body">
          <h3 class="password-label">Old password</h3>
          <input 
            type="password" 
            v-model="oldPassword"
            placeholder="Enter old password"
            class="password-input"
          >
          <h3 class="password-label">New password</h3>
          <input 
            type="password" 
            v-model="newPassword"
            placeholder="Enter new password"
            class="password-input"
          >
          <h3 class="password-label">Repeat new password</h3>
          <input 
            type="password" 
            v-model="duplicateNewPassword"
            placeholder="Repeat new password"
            class="password-input"
          >
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="closePasswordModal">Cancel</button>
          <button class="btn btn-primary" @click="submitPasswordChange">Confirm</button>
        </div>
      </div>
    </div>

    <!-- 登录过期提示框 -->
    <div v-if="showLoginExpiredModal" class="modal-overlay">
      <div class="modal-content">
        <h2>Login status is expired</h2>
        <p>Please login again</p>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="handleLoginExpired">Ok</button>
        </div>
      </div>
    </div>

    <!-- 用户信息模态框 -->
    <div v-if="showUserModal" 
         class="modal-overlay"
         @click.self="showUserModal = false">
      <div class="modal-content user-modal">
        <!-- 用户信息行 -->
        <div class="user-info-row">
          <div class="user-details">
            <div class="user-nickname">{{ userInfo.nickname }}</div>
            <div class="user-username">{{ userInfo.username }}</div>
          </div>
        </div>
        
        <!-- Home按钮行 -->
        <div class="action-row">
          <button class="btn-full btn-text" @click="showUserModal = false">
            Home
          </button>
        </div>
        
        <!-- Logout按钮行 -->
        <div class="action-row">
          <button class="btn-full btn-secondary" @click="handleLogout">
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { ElMessage } from 'element-plus'

export default {
  name: 'AdminAccountsPage',
  data() {
    return {
      users: [],
      showPasswordModal: false,
      selectedUserId: null,
      oldPassword: '',
      newPassword: '',
      duplicateNewPassword: '',
      userInfo: {
        avatar: 'avatar-1.png',
        nickname: '',
        username: ''
      },
      showLoginExpiredModal: false,
      showUserModal: false
    }
  },
  
  created() {
    this.fetchUsers()
    this.fetchUserInfo()
  },

  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/admin/users')
        if (response.data.code === 200) {
          this.users = response.data.data
        }
      } catch (error) {
        if (error.response?.data?.code === 401 || error.response?.status === 401) {
          ElMessage.error('Login status is expired')
          this.$router.push('/admin')
        } else {
          ElMessage.error(error.response?.data?.msg || 'Failed to fetch users')
        }
      }
    },

    handleChangePassword(userId) {
      this.selectedUserId = userId
      this.showPasswordModal = true
      this.oldPassword = ''
      this.newPassword = ''
      this.duplicateNewPassword = ''
    },

    closePasswordModal() {
      this.showPasswordModal = false
      this.selectedUserId = null
      this.oldPassword = ''
      this.newPassword = ''
      this.duplicateNewPassword = ''
    },

    async submitPasswordChange() {
      if (!this.oldPassword || !this.newPassword || !this.duplicateNewPassword) {
        ElMessage.warning('Please fill in all fields')
        return
      }

      if (this.newPassword !== this.duplicateNewPassword) {
        ElMessage.warning('New passwords do not match')
        return
      }

      try {
        const response = await axios.post(`/admin/users/${this.selectedUserId}/password`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })

        if (response.data.code === 200) {
          ElMessage.success('Password changed successfully')
          this.closePasswordModal()
        }
      } catch (error) {
        ElMessage.error('Failed to change password')
      }
    },

    async fetchUserInfo() {
      try {
        const response = await axios.get('/admin/user/info')
        if (response.data.code === 200) {
          this.userInfo = response.data.data
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      }
    },

    handleLoginExpired() {
      this.showLoginExpiredModal = false
      localStorage.removeItem('token')
      this.$router.push('/admin')
    },

    handleLogout() {
      localStorage.removeItem('token')
      this.$router.push('/admin')
    }
  }
}
</script>

<style scoped>
.admin-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 120px;
  background-color: #000;
  color: white;
  padding: 0rem 0;
}

.logo {
  padding: 0rem;
  text-align: center;
  cursor: pointer; /* 添加手型光标 */
  transition: opacity 0.3s; /* 添加过渡效果 */
}

.logo:hover {
  opacity: 0.8; /* 添加悬停效果 */
}

.logo img {
  max-width: 100px;
}

.nav-tabs {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.nav-item {
  padding: 1rem;
  text-decoration: none;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  /* 设置渐变背景 */
  background: grey;
  /* 将背景裁剪到文字 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设为透明,这样就能看到背景 */
  color: transparent;
}

.nav-item.active {
  /* 设置渐变背景 */
  background: linear-gradient(to bottom, #FEF3D5, #FFF1D4, #FDD68D);
  /* 将背景裁剪到文字 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设为透明,这样就能看到背景 */
  color: transparent;
}

.nav-item:hover{
  background-color: white;
}

.nav-item img.nav-icon {
  filter: grayscale(100%); /* 图标颜色为灰色 */
}

.nav-item.active img.nav-icon {
  filter: none; /* 选中时图标颜色恢复 */
}

.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #f5f5f5;
}

.page-title {
  text-align: left;
  margin-bottom: 0;
  font-size: 1.8rem;
}

.content-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.view-tabs {
  padding: 1rem 0 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
}

.view-tab {
  padding: 0.8rem 1.5rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
  margin-right: 1rem;
  position: relative;
  top: 1px;
}

.view-tab:hover {
  color: black;
}

.view-tab.active {
  color: black;
  border-bottom-color: black;
}


.bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
}

.bottom-actions2 {
  display: flex;
  justify-content: flex-end;
  align-items: right;
  padding: 1rem 0;
  width: 100%;
}

.quota-info {
  color: red;
  font-size: 0.9rem;
  text-align: left;
}

.download-button {
  width: auto;  /* 让按钮宽度由内容决定 */
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
}


.films-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f8f9fa;
  font-weight: normal;
  color: #333;
}

td {
  max-width: 0;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

/* Center the content of the first column */
td:first-child, th:first-child {
  text-align: center; /* Center the first column */
}

/* Set width proportions for each column */
th:nth-child(1), td:nth-child(1) { width: 10%; } /* Number */
th:nth-child(2), td:nth-child(2) { width: 25%; } /* Account Type */
th:nth-child(3), td:nth-child(3) { width: 25%; } /* Name */
th:nth-child(4), td:nth-child(4) { width: 20%; } /* Account Password */
th:nth-child(5), td:nth-child(5) { width: 20%; } /* Operation */

.film-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.film-row:hover {
  background-color: #f5f5f5;
}

.content-card {
  background-color: white;
  border-radius: 8px;
  padding: 0rem 0rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #000;
  color: white;
}

.btn-primary:hover {
  background-color: #333;
}

.btn-secondary {
  background-color: #666;
  color: white;
}

.btn-secondary:hover {
  background-color: #999;
}

.btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-outline {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-outline:disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.btn-small {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  display: inline-block;
  width: auto;
}

/* Optional: Add some horizontal centering for the button column */
td:last-child {
  text-align: center;  /* Centers the button in its column */
}

/* 登录过期弹窗样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 2rem;
}

.user-modal {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
}

.user-info-row {
  border-bottom: 1px solid #eee;
}

.user-details {
  text-align: left;
  padding: 0.5rem;
}

.user-nickname {
  font-size: 1rem;
  font-weight: 500;
}

.user-username {
  color: #666;
  font-size: 0.875rem;
}

.action-row {
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #eee;
}

.action-row:last-child {
  border-bottom: none;
}

.btn-full {
  width: 100%;
  padding: 0.5rem;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
}

.btn-text {
  color: #333;
}

.btn-text:hover {
  background-color: #f5f5f5;
}

.btn-secondary {
  color: #ff4d4f;
}

.btn-secondary:hover {
  background-color: #fff1f0;
}

.close-button {
  display: none; /* 隐藏关闭按钮 */
}

.count-badge {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  background-color: #000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.judge-item {
  margin: 4px 0;
}

.mark-tag {
  display: inline-block;
  width: 86px; /* 172px ÷ 2 */
  height: 28px; /* 56px ÷ 2 */
  line-height: 28px; /* 设置行高等于新的高度 */
  font-size: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* 确保背景图片保持原始比例 */
  text-align: center;
  color: grey;
}

.mark-recommended {
  background-image: url('@/assets/recommend_bg.png');
  color: var(--primary-color);
}

.mark-not-recommended {
  border: 1px solid #FE007F;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 0px;    /* 移除上下内边距，只保留左右内边距 */
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
  color: #FE007F;
}

.mark-tag.mark-unreviewed {
  border: 1px solid grey;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 12px;    /* 移除上下内边距，只保留左右内边距 */
  color: grey;
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
}



.score-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star-icon {
  width: 18px;
  height: 18px;
}

.judge-modal {
  max-width: 600px;
  width: 90%;
}

.judge-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem 0;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
}

.judge-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.judge-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.judge-option {
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.judge-option:hover {
  border-color: lightgrey;
  background-color: #f9f9f9;
}

.judge-option.selected {
  background-color: lightgrey;
  border-color: lightgrey;
}

.judge-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.judge-name {
  font-weight: 500;
  font-size: 1rem;
}

.judge-account {
  color: #666;
  font-size: 0.9rem;
}

.judge-option.selected {
  font-weight: 500;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
}


.nav-icon {
  width: 20px;
  height: 20px;
}

.assigned-text {
  color: #333;
  cursor: default;
}

.clickable-text {
  color: red;
  cursor: pointer;
  text-decoration: none;
}

.clickable-text:hover {
  text-decoration: underline;
}

.info-text {
  display: flex;
  align-items: left;
  gap: 4px;  /* 图标和文字之间的间距 */
  font-size: 0.8rem;
}

.info-icon {
  width: 16px;
  height: 16px;
}

.search-box {
  position: relative;
  margin: 1rem 0;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #000;
}

.search-input::placeholder {
  color: #999;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border: 1px dashed #000;
  border-radius: 4px;
  background: transparent;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.close-button:hover {
  border-color: #666;
}

.close-button img {
  width: 100%;
  height: 100%;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.page-size-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-size-selector select {
  padding: 0.3rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.page-info {
  color: #666;
}

.page-buttons {
  display: flex;
  gap: 0.5rem;
}

.page-buttons .btn {
  padding: 0.3rem 0.8rem;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
}

.page-buttons .btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.arrow {
  font-size: 1.2rem;
  line-height: 1;
}

.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.search-box {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  min-width: 300px;
}

.search-field-select {
  position: relative;
  min-width: 120px;
}

.search-field-select select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px; /* 右侧留出箭头空间 */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.search-field-select::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none; /* 确保箭头不会影响选择 */
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .search-field-select select {
      padding-right: 32px; /* Safari 中确保有足够空间显示箭头 */
    }
  }
}

/* 悬停和焦点状态 */
.search-field-select select:hover {
  border-color: #999;
}

.search-field-select select:focus {
  outline: none;
  border-color: #666;
}

.search-input-wrapper {
  display: flex;
  flex: 1;
  gap: 0.5rem;
}

.search-input-wrapper input {
  flex: 1;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.search-button:hover {
  opacity: 0.9;
}

.filter-dropdown {
  position: relative;
  width: 200px;
}

.filter-title {
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  background-color: white;
  color: #666;
  z-index: 1;
}

.filter-select-wrapper {
  position: relative;
}

.filter-select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.filter-select-wrapper::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .filter-select-wrapper select {
      padding-right: 32px;
    }
  }
}

/* 悬停和焦点状态 */
.filter-select-wrapper select:hover {
  border-color: #999;
}

.filter-select-wrapper select:focus {
  outline: none;
  border-color: #666;
}

.title-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.film-title {
  font-weight: 420;
  color: red;
}

.film-directors {
  font-size: 0.8em;
  color: #666;
}

.film-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.film-row:hover {
  background-color: #f5f5f5;
}

.detail-container {
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.section-title {
  font-size: 1.2rem;
  margin: 1rem;
  color: #333;
  text-align: left;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item label {
  font-size: 0.9rem;
  color: #666;
}

.description {
  white-space: pre-line;
  line-height: 1.5;
}

.creators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.creator-group h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

.creator-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.creator-item {
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
  font-size: 0.9rem;
}

.back-button:hover {
  color: #333;
}

.back-icon {
  width: 16px;
  height: 16px;
}

.page-title {
  margin: 0;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.table-wrapper {
  overflow-x: auto;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 0px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.film-poster {
  width: 120px;     /* 增加基础宽度 */
  height: 180px;    /* 保持 2:3 的宽高比 */
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 1;       /* 确保悬停时图片显示在上层 */
}
/* 调整表格列宽以适应更大的海报 */
.info-table th:nth-child(1) {  /* Poster 列 */
  width: 18%;              /* 略微增加列宽 */
}

.info-table th:nth-child(2) {  /* Title 列 */
  width: 27%;              /* 相应调整 */
}

.info-table th:nth-child(3) {  /* Synopsis 列 */
  width: 40%;              /* 保持不变 */
}

.info-table th:nth-child(4) {  /* Duration 列 */
  width: 15%;              /* 保持不变 */
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.team-group h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #666;
}

.member-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.member-item {
  padding: 0.5rem 1rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
}

.film-info-cell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.film-info-cell .title {
  font-weight: 500;
}

.play-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
}

.play-button:hover {
  background-color: #333;
}

.password-value {
  font-family: monospace;
  background: #f5f5f5;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  color: red;  /* Add red color */
  font-size: 1.1rem;  /* Increase font size */
  font-weight: 500;  /* Add slightly bolder font weight */
}

.copy-button {
  background: none;
  border: 1px solid #ddd;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.3s;
}

.copy-button:hover {
  border-color: #000;
  background: #f5f5f5;
}

.team-section {
  margin-bottom: 2rem;
}

.team-section:last-child {
  margin-bottom: 0;
}

.team-section h3 {
  font-size: 1.2rem;
  margin: 1rem 1rem;
  color: #333;
}

.director-photo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  /* 改为顶部对齐 */
  margin-bottom: 2rem;
  width: 100%;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;  /* 标题和返回按钮之间的间距 */
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.user-avatar:hover {
  opacity: 0.8;
}


.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.user-avatar-large {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.user-nickname {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.user-username {
  color: #666;
  font-size: 0.9rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button img {
  width: 16px;
  height: 16px;
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 1rem 0rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}


.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.btn-secondary:hover {
  background-color: #f5f5f5;
}

.btn-primary {
  background-color: #ddd;
  border: 1px solid #ddd;
  color: #fff;
  min-width: 160px;
}

.btn-primary.btn-active {
  background-color: #000;
  border: 1px solid #000;
}

.btn-primary:disabled {
  background-color: #ddd;
  border: 1px solid #ddd;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):hover {
  opacity: 0.9;
}

/* 添加批量指定评委按钮样式 */
.batch-assign-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.batch-assign-button:hover {
  opacity: 0.9;
}

/* 调整搜索过滤区域的样式以适应新按钮 */
.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.search-box {
  display: flex;
  gap: 0.5rem;
  flex: 1;
  min-width: 300px;
}

.search-field-select select {
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 120px;
}

.search-input-wrapper {
  display: flex;
  flex: 1;
  gap: 0.5rem;
}

.search-input-wrapper input {
  flex: 1;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.search-button:hover {
  opacity: 0.9;
}

.filter-dropdown {
  position: relative;
  width: 200px;
}

.filter-title {
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  background-color: white;
  color: #666;
  z-index: 1;
}

.filter-select-wrapper {
  position: relative;
}

.filter-select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  /* 重置默认样式 */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 添加自定义下拉箭头 */
.filter-select-wrapper::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .filter-select-wrapper select {
      padding-right: 32px;
    }
  }
}

/* 悬停和焦点状态 */
.filter-select-wrapper select:hover {
  border-color: #999;
}

.filter-select-wrapper select:focus {
  outline: none;
  border-color: #666;
}

.detail-container {
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.table-wrapper {
  overflow-x: auto;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.info-table th,
.info-table td {
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: left;
}

.info-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  /* 改为顶部对齐 */
  margin-bottom: 2rem;
  width: 100%;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;  /* 标题和返回按钮之间的间距 */
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.user-avatar:hover {
  opacity: 0.8;
}


.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.user-avatar-large {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.user-nickname {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.user-username {
  color: #666;
  font-size: 0.9rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button img {
  width: 16px;
  height: 16px;
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 1rem 0rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}


.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #666;
}

.btn-secondary:hover {
  background-color: #f5f5f5;
}

.btn-primary {
  background-color: #ddd;
  border: 1px solid #ddd;
  color: #fff;
  min-width: 160px;
}

.btn-primary.btn-active {
  background-color: #000;
  border: 1px solid #000;
}

.btn-primary:disabled {
  background-color: #ddd;
  border: 1px solid #ddd;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):hover {
  opacity: 0.9;
}

/* 批量指定评委确认弹窗 */
.batch-assign-modal {
  max-width: 500px;
  width: 90%;
}

.warning-message {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}

.warning-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.warning-message p {
  margin: 0;
  line-height: 1.5;
  color: #666;
  text-align: left;
}

.modal-body {
  padding: 1rem 0;
}

.status-select {
  padding: 4px 12px;
  height: 48px;
  border-radius: 10px;  /* 圆角边框 */
  border: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  appearance: none;  /* 移除默认的下拉箭头 */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('@/assets/dropdown-arrow.png');  /* 自定义下拉箭头 */
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
  padding-right: 28px;  /* 为箭头留出空间 */
  transition: border-color 0.3s;
}

.status-select:hover {
  border-color: #999;
}

.status-select:focus {
  outline: none;
  border-color: #666;
}

/* 移除 Firefox 中的下拉箭头 */
.status-select::-moz-focus-inner {
  border: 0;
}

/* 移除 IE 中的下拉箭头 */
.status-select::-ms-expand {
  display: none;
}

.confirm-modal {
  max-width: 500px;
  width: 90%;
}

.confirm-modal .modal-body {
  padding: 1.5rem 0;
  text-align: left;
}

.confirm-modal .modal-body p {
  margin: 0;
  line-height: 1.5;
  color: #666;
}

/* Remove dividing lines between rows */
.films-table tr {
  border-bottom: none; /* Remove bottom border for table rows */
}

/* Optional: Add some padding to the rows for better spacing */
.films-table tr td {
  padding: 0.75rem; /* Adjust padding for table cells */
}

.role-label-organizer {
  border: 1px solid var(--primary-color); /* Gold border for Organizer */
  color: var(--primary-color); /* Gold text for Organizer */
  padding: 0.25rem 0.5rem; /* Padding for the label */
  border-radius: 4px; /* Rounded corners */
  background-color: transparent; /* Transparent background */
  display: inline-block; /* Ensure it only takes up necessary space */
}

.role-label-judge {
  border: 1px solid red; /* Red border for Judge */
  color: red; /* Red text for Judge */
  padding: 0.25rem 0.5rem; /* Padding for the label */
  border-radius: 4px; /* Rounded corners */
  background-color: transparent; /* Transparent background */
  display: inline-block; /* Ensure it only takes up necessary space */
}

.password-label {
  text-align: left;
  padding: 0.5rem;
}

.password-input {
  width: 100%; /* Full width for the input fields */
  padding: 0.5rem; /* Padding for better spacing */
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 1rem; /* Space between inputs */
  font-size: 1rem; /* Font size */
  transition: border-color 0.3s; /* Transition for border color */
}

.password-input:focus {
  border-color: #000; /* Darker border on focus */
  outline: none; /* Remove default outline */
}

.password-modal {
  min-width: 400px; /* 设置最小宽度 */
}

</style>