<template>
  <div class="admin-layout">
    <!-- 左侧导航栏 -->
    <div class="sidebar">
      <div class="logo" @click="$router.push('/judge')">
        <img src="@/assets/project-logo.png" alt="Logo" />
      </div>
      <nav class="nav-tabs">
        <router-link to="/judge/films" class="nav-item active">
          <img src="@/assets/tab-icon2.png" alt="Judge Icon" class="nav-icon">
          <span>Review Works</span>
        </router-link>
      </nav>
    </div>

    <!-- 右侧内容区 -->
    <div class="main-content">
      <div class="header-section">
        <div class="title-section">
          <h1 class="page-title">Review Works</h1>
        </div>
        <div class="user-profile">
          <img 
            :src="require(`@/assets/avatar/${userInfo.avatar}`)" 
            alt="User Avatar" 
            class="user-avatar"
            @click="showUserModal = true"
          >
        </div>
      </div>
      
      <div class="view-tabs">
        <button 
          :class="['view-tab', { active: currentView === 'list' }]"
          @click="switchView('list')"
        >
          Review Works
        </button>
        
        <button 
          :class="['view-tab', { active: currentView === 'detail' }]"
          @click="switchView('detail')"
          :disabled="!selectedFilm"
        >
          Work Details
        </button>
      </div>

      <div v-if="currentView === 'list'" class="content-card">
        <!-- Tab 切换按钮 -->
        <div class="tab-buttons">
          <button 
            v-for="tab in tabs" 
            :key="tab.value"
            :class="['tab-button', { active: currentTab === tab.value }]"
            @click="currentTab = tab.value"
          >
            {{ tab.label }} 
            <span v-if="statusCounts[tab.value]" class="count-badge">
              {{ statusCounts[tab.value] }}
            </span>
          </button>
        </div>
        
        <!-- 影片列表 -->
        <div class="films-table">
          <div class="search-filter-section">
            <div class="search-box">
              <div class="search-field-select">
                <select v-model="searchField">
                  <option v-for="field in searchFields" 
                          :key="field.value" 
                          :value="field.value">
                    {{ field.label }}
                  </option>
                </select>
              </div>
              <div class="search-input-wrapper">
                <input 
                  type="text" 
                  v-model="searchQuery"
                  @keyup.enter="handleSearch"
                  placeholder="Enter search terms..."
                >
                <button class="search-button" @click="handleSearch">
                  Search
                </button>
              </div>
            </div>
            
            <div class="filter-dropdown">
              <div class="filter-title">Mark</div>
              <div class="filter-select-wrapper">
                <select v-model="markFilter" @change="handleSearch">
                  <option v-for="option in markOptions" 
                          :key="option.value" 
                          :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          
          <table>
            <thead>
              <tr>
                <th>Work</th>
                <th>Duration</th>
                <th>Director</th>
                <th>Producer</th>
                <th>Current<br>round mark</th>
                <th>Current<br>round score</th>
                <th>Round</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="film in films" 
                  :key="film.id" 
                  @click="viewFilmDetail(film)"
                  class="film-row">
                <td>{{ film.title }}</td>
                <td>{{ formatDuration(film.duration) }}</td>
                <td>{{ formatDirectors(film.directors) }}</td>
                <td>{{ formatProducers(film.producers) }}</td>
                <td>
                  <span :class="['mark-tag', getMarkClass(film.mark)]">
                    {{ getMarkText(film.mark) }}
                  </span>
                </td>
                <td>
                  <span class="score-value">
                    {{ formatScore(film.score) }}
                    <img src="@/assets/star-icon.png" alt="Star" class="star-icon">
                  </span>
                </td>
                <td>{{ film.round }}</td>
              </tr>
            </tbody>
          </table>

          <!-- 分页组件 -->
          <div class="pagination-container">
            <div class="page-size-selector">
              <span>Number of lines per page:</span>
              <select v-model="pageSize" @change="handlePageSizeChange">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            
            <div class="page-info">
              From line {{ (currentPage - 1) * pageSize + 1 }} to line {{ Math.min(currentPage * pageSize, total) }}, a total of {{ total }} lines
            </div>
            
            <div class="page-buttons">
              <button 
                class="btn btn-outline" 
                :disabled="currentPage === 1"
                @click="changePage(currentPage - 1)"
              >
                <span class="arrow">←</span>
              </button>
              <button 
                class="btn btn-outline"
                :disabled="currentPage >= Math.ceil(total / pageSize)"
                @click="changePage(currentPage + 1)"
              >
                <span class="arrow">→</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="film-detail">
        <!-- 播放器区域 -->
        <div class="player-section">
          <!-- OSS, direct video URLs -->
          <video 
            v-if="shouldShowVideoPlayer"
            controls
            autoplay
            muted
            class="video-player"
            :key="submissionUrl"
            :src="submissionUrl"
            @loadeddata="handleVideoLoaded"
          >
            This browser cannot play video
          </video>

          <!-- YouTube or Vimeo embed -->
          <div v-else-if="isEmbeddedVideo" class="video-container">
            <div class="video-wrapper">
              <iframe
                :src="getEmbedUrl"
                class="video-player"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <a 
              :href="selectedFilm.submission_url" 
              target="_blank" 
              class="external-link"
            >
              Watch on {{ getVideoPlatform }}
            </a>
          </div>

          <!-- FilmFreeway link -->
          <div v-else-if="isFilmFreeway" class="filmfreeway-container">
            <button 
              @click="openExternalLink(selectedFilm.submission_url)"
              class="filmfreeway-button"
            >
              Watch on FilmFreeway
            </button>
          </div>

          <!-- Loading placeholder -->
          <div v-else class="video-placeholder">
            Video is loading
          </div>

          <!-- Password display if available -->
          <div v-if="selectedFilm.submission_password" class="password-info">
            Password: {{ selectedFilm.submission_password }}
          </div>
        </div>

        <!-- 评分区域 -->
        <div class="rating-section">
          <div class="rating-header">
            <h3>Film Score</h3>
          </div>
          <div class="rating-form">
            <div class="score-input">
              <label>Score</label>
              <input 
                type="number" 
                v-model="ratingScore" 
                min="0" 
                max="100" 
                step="1"
                @input="validateScore"
                @blur="validateScore"
              >
            </div>
            <div class="mark-input">
              <div class="radio-group">
                <label class="radio-label">
                  <input 
                    type="radio" 
                    v-model="ratingMark" 
                    :value="0"
                    name="mark"
                    @click="handleMarkClick(0)"
                  >
                  Not considered
                </label>
                <label class="radio-label">
                  <input 
                    type="radio" 
                    v-model="ratingMark" 
                    :value="1"
                    name="mark"
                    @click="handleMarkClick(1)"
                  >
                  Recommended
                </label>
              </div>
            </div>
            <button class="submit-rating" @click="submitRating">Submit</button>
          </div>
          <div class="rate-hint-section">
            <span class="score-hint">*Score from 0 to 100</span>
            <span class="quota-hint">Remaining Recommendation Quota: {{ 15 - (usedQuota || 0) }}/15</span>
          </div>
        </div>

        <!-- 影片介绍区域 -->
        <div class="film-info-section">
          <div class="film-info-grid">
            <!-- Left Column -->
            <div class="film-info-left">
              <h3>Film Introduction</h3>
              <div class="film-poster">
                <img :src="(selectedFilm.cover_image || require('@/assets/project-logo.png')).replace(/\+/g, '%2B')" alt="Film Poster">
              </div>
            </div>
            
            <!-- Right Column -->
            <div class="film-info-right">
              <div class="film-title">
                <h2>{{ selectedFilm.title }}</h2>
              </div>
              <div class="film-description">
                <p>{{ selectedFilm.description || '' }}</p>
              </div>
              <div class="film-duration">
                <label>Duration:</label>
                <span>{{ formatDuration(selectedFilm.duration) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 主创介绍区域 -->
        <div class="creators-section">
          <h3>Creative Team Information</h3>
          
          <!-- Directors Table -->
          <div class="creator-table-section">
            <table class="creator-table">
              <thead>
                <tr>
                  <th>Director's Photo</th>
                  <th>Director</th>
                  <th>English Name</th>
                  <th>Brief Introduction</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="director in selectedFilm.directors" :key="director.id">
                  <td>
                    <img 
                      :src="director.photo_url || require('@/assets/project-logo.png')" 
                      alt="Director photo" 
                      class="creator-photo"
                    >
                  </td>
                  <td>{{ director.name }}</td>
                  <td>{{ director.english_name }}</td>
                  <td>{{ director.introduction }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Screenwriters Table -->
          <div class="creator-table-section">
            <table class="creator-table">
              <thead>
                <tr>
                  <th>Screenwriter</th>
                  <th>English Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="writer in selectedFilm.screenwriters" :key="writer.id">
                  <td>{{ writer.name }}</td>
                  <td>{{ writer.english_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Main Cast Table -->
          <div class="creator-table-section">
            <table class="creator-table">
              <thead>
                <tr>
                  <th>Lead Actor</th>
                  <th>English Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="actor in selectedFilm.actors" :key="actor.id">
                  <td>{{ actor.name }}</td>
                  <td>{{ actor.english_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Submitter Biography Table -->
          <div class="creator-table-section">
            <h4>Submitter Biography</h4>
            <table class="creator-table">
              <tbody>
                <tr>
                  <td>{{ selectedFilm.other_crew || 'No biography provided' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showUserModal" 
        class="modal-overlay"
        @click.self="showUserModal = false">
    <div class="modal-content user-modal">
      <!-- 第一行：用户信息 -->
      <div class="user-info-row">
        <div class="user-details">
          <div class="user-nickname">{{ userInfo.nickname }}</div>
          <div class="user-username">{{ userInfo.username }}</div>
        </div>
      </div>
      
      <!-- 第二行：Home按钮 -->
      <div class="action-row">
        <button class="btn-full btn-text" @click="showUserModal = false">
          Home
        </button>
      </div>
      
      <!-- 第三行：Logout按钮 -->
      <div class="action-row">
        <button class="btn-full btn-secondary" @click="handleLogout">
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import { ElMessage } from 'element-plus'

export default {
  name: 'JudgeFilmsPage',
  data() {
    return {
      currentTab: 0, // 0: 未评审, 1: 已评审
      currentView: 'list', // 'list' or 'detail'
      films: [],
      statusCounts: {},
      selectedFilm: null,
      tabs: [
        { label: 'Unreviewed', value: 0 },
        { label: 'Reviewed', value: 1 }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      ratingScore: null,
      ratingMark: null,
      submissionUrl: null,
      searchField: 'title',
      searchQuery: '',
      markFilter: '',
      markOptions: [
        { label: 'All', value: '' },
        { label: 'Recommended', value: '1' },
        { label: 'Not considered', value: '0' }
      ],
      searchFields: [
        { label: 'Title', value: 'title' },
        { label: 'Director', value: 'director' },
        { label: 'Producer', value: 'producer' }
      ],
      showUserModal: false,
      userInfo: {
        avatar: 'avatar-1.png', // Default avatar
        nickname: '',
        username: ''
      },
      usedQuota: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    },
    shouldShowVideoPlayer() {
      return this.selectedFilm.submission_url && 
             this.submissionUrl && 
             !this.isEmbeddedVideo && 
             !this.isFilmFreeway;
    },
    isEmbeddedVideo() {
      const url = this.selectedFilm.submission_url?.toLowerCase() || '';
      return url.includes('youtube.com') || 
             url.includes('youtu.be') || 
             url.includes('vimeo.com');
    },
    isFilmFreeway() {
      return this.selectedFilm.submission_url?.toLowerCase().includes('filmfreeway.com');
    },
    getVideoPlatform() {
      const url = this.selectedFilm.submission_url?.toLowerCase() || '';
      if (url.includes('youtu')) return 'YouTube';
      if (url.includes('vimeo')) return 'Vimeo';
      return '';
    },
    getEmbedUrl() {
      const url = this.selectedFilm.submission_url;
      if (!url) return '';

      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        const videoId = this.getYouTubeVideoId(url);
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (url.includes('vimeo.com')) {
        const videoId = this.getVimeoVideoId(url);
        
        const finalurl = `https://player.vimeo.com/video/${videoId}`
        console.log(finalurl)
        return finalurl;
      }
      return '';
    }
  },
  methods: {
    async fetchFilms() {
      try {
        const response = await axios.get('/judge/films', {
          params: {
            scored: this.currentTab,
            page: this.currentPage,
            limit: this.pageSize,
            search_field: this.searchField,
            search_query: this.searchQuery,
            markFilter: this.markFilter
          }
        })
        
        // 检查响应中的 code
        if (response.data.code === 401) {
          ElMessage.error('Login status is expired')
          this.$router.push('/admin')
          return
        }
        
        if (response.data.code === 200) {
          this.films = response.data.data.list
          this.total = response.data.data.total
          this.statusCounts = response.data.data.counts
        }
      } catch (error) {
        console.error('Error details:', error.response || error)
        
        if (error.response?.data?.code === 401 || error.response?.status === 401) {
          ElMessage.error('Login status is expired')
          this.$router.push('/admin')
        } else {
          ElMessage.error(error.response?.data?.msg || 'Failed to fetch film list')
        }
      }
    },
    formatDuration(seconds) {
      if (!seconds) return '--';
      
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      
      const parts = [];
      
      if (hours > 0) {
        parts.push(hours.toString().padStart(2, '0'));
      }
      
      parts.push(minutes.toString().padStart(2, '0'));
      parts.push(remainingSeconds.toString().padStart(2, '0'));
      
      return parts.join(':');
    },
    getStatusCount(status) {
      return this.statusCounts[status] || 0
    },
    changePage(page) {
      this.currentPage = page
      this.fetchFilms()
    },
    switchView(view) {
      this.currentView = view
    },
    getVideoUrl(submissionUrl) {
      if (submissionUrl.startsWith('oss://')) {
        // 对于 OSS 存储，需要通过后端获取签名 URL
        this.fetchOssUrl();
        return ''; // 暂时返回空，等待 URL 获取完成
      } else if (submissionUrl.startsWith('http://') || submissionUrl.startsWith('https://')) {
        // 已经是完整的 URL（可能是其他来源）
        return submissionUrl;
      } else {
        // 本地文件，需要通过后端流式传输
        return this.submissionUrl;
      }
    },
    async fetchOssUrl() {
      try {
        const response = await axios.get(`/judge/films/${this.selectedFilm.id}/stream`)
        
        if (response.data.code === 200) {
          this.submissionUrl = response.data.data.url
        } else {
          ElMessage.error(response.data.msg || 'Failed to fetch video link')
        }
      } catch (error) {
        console.error('Failed to fetch video link:', error)
        ElMessage.error('Failed to fetch video link')
      }
    },
    async viewFilmDetail(film) {
      this.selectedFilm = film;
      this.currentView = 'detail';
      this.submissionUrl = null;
      
      this.ratingScore = film.score;
      this.ratingMark = film.mark;
      
      if (film.submission_url) {
        if (film.submission_url.startsWith('oss://')) {
          await this.fetchOssUrl();
        } else if (film.submission_url.startsWith('http://') || film.submission_url.startsWith('https://')) {
          this.submissionUrl = film.submission_url.replace(/\+/g, '%2B');
        } else {
          await this.fetchLocalVideo();
        }
      }

      window.scrollTo(0, 0);
    },
    async fetchLocalVideo() {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(
          `http://localhost:8000/judge/films/${this.selectedFilm.id}/stream`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        if (response.ok) {
          const blob = await response.blob();
          this.submissionUrl = URL.createObjectURL(blob);
        } else {
          const data = await response.json();
          if (data.code === 401) {
            ElMessage.error('Login status is expired');
            this.$router.push('/admin');
          } else {
            ElMessage.error(data.msg || 'Failed to fetch load video');
          }
        }
      } catch (error) {
        console.error('Failed to fetch load video:', error);
        ElMessage.error('Failed to fetch load video');
      }
    },
    async submitRating() {
      // 如果要推荐，且已达到配额上限，则阻止提交
      if (this.ratingMark === '1' && (15 - this.usedQuota) <= 0) {
        ElMessage.error('You have reached the maximum recommendation quota (15)')
        return
      }
      
      try {
        const response = await axios.post(
          `/judge/films/${this.selectedFilm.id}/rate`,
          {
            score: this.ratingScore,
            is_recommended: this.ratingMark
          }
        )
        
        if (response.data.code === 200) {
          ElMessage.success('Submit success')
          this.currentView = 'list'
          this.fetchFilms()
          this.fetchQuota()
        } else {
          ElMessage.error(response?.data?.msg || 'Submit failed')
        }
      } catch (error) {
        ElMessage.error(error.response?.data?.msg || 'Submit failed')
      }
    },
    handleVideoLoaded(event) {
      const video = event.target;
      video.muted = false;  // 取消静音
    },
    handlePageSizeChange() {
      this.currentPage = 1;
      this.fetchFilms();
    },
    handleSearch() {
      this.currentPage = 1;
      this.fetchFilms();
    },
    async fetchUserInfo() {
      try {
        const response = await axios.get('/judge/user/info')
        if (response.data.code === 200) {
          this.userInfo = response.data.data
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error)
      }
    },
    handleLogout() {
      localStorage.removeItem('token')
      this.$router.push('/judge')
    },
    async fetchQuota() {
      try {
        const response = await axios.get('/judge/films/quota')
        if (response.data.code === 200) {
          this.usedQuota = response.data.data.used_quota
        }
      } catch (error) {
        console.error('Failed to fetch quota:', error)
      }
    },
    getYouTubeVideoId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },
    getVimeoVideoId(url) {
      // 匹配两种格式：普通ID和带hash的ID
      const regExp = /vimeo\.com\/([0-9]+)(?:\/([a-z0-9]+))?(?:\?|$)/;
      const match = url.match(regExp);
      if (!match) return null;
      
      const videoId = match[1];
      const hash = match[2];
      
      // 根据是否有hash构建不同格式的URL
      return hash 
        ? `${videoId}?h=${hash}`
        : videoId;
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    formatDirectors(directors) {
      if (!directors || !Array.isArray(directors)) return '-';
      return directors.map(d => d.name).join(', ');
    },
    formatProducers(producers) {
      if (!producers || !Array.isArray(producers)) return '-';
      return producers.map(p => p.name).join(', ');
    },
    validateScore() {
      if (this.ratingScore === null || this.ratingScore === '') return;
      
      let score = parseInt(this.ratingScore);
      if (isNaN(score)) {
        this.ratingScore = null;
      } else {
        score = Math.max(0, Math.min(100, score));
        this.ratingScore = score;
      }
    },
    handleMarkClick(value) {
      // If clicking the currently selected radio button, unselect it
      if (this.ratingMark === value) {
        this.ratingMark = null;
      }
    },
    getMarkClass(mark) {
      if (mark === null) return 'not-selected';
      return mark === 1 ? 'recommended' : 'not-considered';
    },

    getMarkText(mark) {
      if (mark === null) return 'Unreviewed';
      return mark === 1 ? 'Recommended' : 'Not considered';
    },
    formatScore(score) {
      if (!score && score !== 0) return '-';
      return Number(score).toFixed(2);
    },
    showFilmDetail(film) {
      this.selectedFilm = film
    }
  },
  watch: {
    currentTab() {
      this.currentPage = 1
      this.fetchFilms()
    }
  },
  created() {
    this.fetchFilms()
    this.fetchUserInfo()
    this.fetchQuota()
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.handlePopState)
  }
}
</script>

<style scoped>
.admin-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 120px;
  background-color: #000;
  color: white;
  padding: 0rem 0;
}

.logo {
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.logo img {
  max-width: 100px;
}

.nav-tabs {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.nav-item {
  padding: 1rem;
  text-decoration: none;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  /* 设置渐变背景 */
  background: linear-gradient(to bottom, #FEF3D5, #FFF1D4, #FDD68D);
  /* 将背景裁剪到文字 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 将文字颜色设为透明,这样就能看到背景 */
  color: transparent;
}

.nav-item:hover{
  background-color: #333;
}

.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #f5f5f5;
}

.page-title {
  text-align: left;
  margin-bottom: 0;
  font-size: 1.8rem;
}

.content-card {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem 0rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.tab-button:hover,
.tab-button.active {
  color: black;
  border-bottom-color: black;
}

.count-badge {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  background-color: #000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.films-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f8f9fa;
  font-weight: normal; 
}

.score-value {
  font-weight: 500;
  color: #333;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.star-icon {
  width: 16px;
  height: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.btn-outline {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-outline:disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.page-info {
  color: #666;
}

.view-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.view-tab {
  padding: 0.8rem 0rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.view-tab:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.view-tab.active {
  color: black;
  font-weight: bold; /* 添加这行使激活状态的文字加粗 */
}

.film-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.film-row:hover {
  background-color: #f5f5f5;
}

.film-detail {
  padding: 0.5rem 0rem;
}

/* 播放器区域样式 */
.player-section {
  margin-bottom: 2rem;
  height: 480px;
  background: #000;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  background: #000;
}

/* 评分区域样式 */
.rating-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.rating-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.current-score {
  color: var(--primary-color);
  font-weight: 600;
}

.rating-form {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.score-input, .mark-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.score-input input {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.mark-input select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
}

.submit-rating {
  padding: 0.5rem 1.5rem;
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.submit-rating:hover {
  opacity: 0.9;
}

.rate-hint-section {
  display: flex;
  justify-content: left;
  margin-top: 0.5rem;
  gap: 2rem;
}

.score-hint {
  text-align: left;
}

.quota-hint {
  color: red;
}

/* 影片介绍区域样式 */
.film-info-section {
  margin-bottom: 2rem;
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
}

.film-info-grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 2rem;
}

.film-info-left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.film-info-left h3 {
  margin: 0rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}

.film-poster {
  width: 100%;
  aspect-ratio: 2/3;
  background: #eee;
  border-radius: 4px;
  border-radius: 12px;
  overflow: hidden;
}

.film-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.film-info-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.film-title h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}

.film-duration {
  display: flex;
  gap: 0.5rem;
  color: #666;
}

.film-description {
  text-align: left;
  line-height: 1.6;
  color: #333;
}

/* 主创介绍区域样式 */
.creators-section {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: left;
}

.creators-section h3 {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.creator-table-section {
  margin-bottom: 2rem;
}

.creator-table-section h4 {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}

.creator-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  white-space: pre-wrap;
}

.creator-table th,
.creator-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.creator-table th {
  background: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.creator-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.creator-table tbody tr:last-child td {
  border-bottom: none;
}

.radio-group {
  display: flex;
  gap: 1.5rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #333;
}

/* Style for the radio button */
.radio-label input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #666;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s;
}

/* Create the inner circle */
.radio-label input[type="radio"]::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
}

.radio-label input[type="radio"]:checked::after {
  background-color: black;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}

.page-size-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-size-selector select {
  padding: 0.3rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.page-info {
  color: #666;
}

.page-buttons {
  display: flex;
  gap: 0.5rem;
}

.arrow {
  font-size: 1.2rem;
  line-height: 1;
}

.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.search-box {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.search-field-select {
  position: relative;
  min-width: 120px;
}

.search-field-select select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-field-select::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

.search-input-wrapper {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.search-input-wrapper input {
  flex: 1;
  height: 40px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  padding: 0.5rem 2rem;
  min-width: 100px;
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: opacity 0.3s;
}

.search-button:hover {
  opacity: 0.8;
}

.filter-dropdown {
  position: relative;
  width: 200px;
}

.filter-title {
  position: absolute;
  top: -8px; /* 调整位置 */
  left: 10px;
  padding: 5px 5px;
  font-size: 12px;
  background-color: white;
  color: #666;
  z-index: 1;
  /* 移除 transform */
}

.filter-select-wrapper {
  position: relative;
}

.filter-select-wrapper select {
  width: 100%;
  height: 40px;
  padding: 0 32px 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.filter-select-wrapper::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  pointer-events: none;
}

/* Safari 特定样式 */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .search-field-select select,
    .filter-select-wrapper select {
      padding-right: 32px;
    }
  }
}

/* 悬停和焦点状态 */
.search-field-select select:hover,
.filter-select-wrapper select:hover {
  border-color: #999;
}

.search-field-select select:focus,
.filter-select-wrapper select:focus {
  outline: none;
  border-color: #666;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.user-avatar:hover {
  opacity: 0.8;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 300px;
}

.user-modal {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
}

.user-info-row {
  border-bottom: 1px solid #eee;
}

.user-details {
  text-align: left;
  padding: 0.5rem;
}

.user-nickname {
  font-size: 1rem;
  font-weight: 500;
}

.user-username {
  color: #666;
  font-size: 0.875rem;
}

.action-row {
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #eee;
}

.action-row:last-child {
  border-bottom: none;
}

.btn-full {
  width: 100%;
  padding: 0.5rem;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-wrapper .video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.external-link {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.external-link:hover {
  background: rgba(0, 0, 0, 0.9);
}

.filmfreeway-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filmfreeway-button {
  padding: 1rem 2rem;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: opacity 0.3s;
}

.filmfreeway-button:hover {
  opacity: 0.8;
}

.password-info {
  margin-top: 1rem;
  padding: 0.8rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-family: monospace;
  font-size: 1.1rem;
  color: #ff0000;
  font-weight: 500;
}

/* 修改表格单元格样式 */
th:nth-child(5), /* Mark column header */
th:nth-child(6), /* Score column header */
td:nth-child(5), /* Mark column */
td:nth-child(6) { /* Score column */
  text-align: center;
}

.mark-tag {
  display: inline-block;
  width: 86px; /* 172px ÷ 2 */
  height: 28px; /* 56px ÷ 2 */
  line-height: 28px; /* 设置行高等于新的高度 */
  font-size: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* 确保背景图片保持原始比例 */
  text-align: center;
  color: grey;
}

.mark-tag.recommended {
  background-image: url('@/assets/recommend_bg.png');
  color: var(--primary-color);
}

.mark-tag.not-considered {
  border: 1px solid #FE007F;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 0px;    /* 移除上下内边距，只保留左右内边距 */
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
  color: #FE007F;
}

.mark-tag.not-selected {
  border: 1px solid grey;  /* 指定边框宽度、样式和颜色 */
  border-radius: 10px;
  line-height: 28px;  /* 与容器高度相同，确保文字垂直居中 */
  padding: 0 12px;    /* 移除上下内边距，只保留左右内边距 */
  color: grey;
  height: 28px;      /* 与其他状态保持一致的高度 */
  display: inline-block;
}

</style>