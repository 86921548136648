<template>
  <div class="film-detail-container">
    <!-- Navigation Bar -->
    <div class="nav-bar">
      <div class="back-button" @click="$router.go(-1)">
        <img :src="backIcon" alt="Back" class="back-icon">
      </div>
      <h1 class="page-title">Film Detail</h1>
      <div class="placeholder"></div>
    </div>

    <div v-if="film" class="content">
      <!-- Player Section -->
      <div class="player-section">
        <video 
          v-if="shouldShowVideoPlayer"
          controls
          class="video-player"
          :key="submissionUrl"
          :src="submissionUrl"
        >
          This browser cannot play video
        </video>
        
        <iframe
          v-else-if="isEmbeddedVideo"
          :src="getEmbedUrl"
          class="video-player"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>

        <div v-else-if="isFilmFreeway" class="filmfreeway-container">
          <button 
            @click="openExternalLink(submissionUrl)"
            class="filmfreeway-button"
          >
            Watch on FilmFreeway
          </button>
        </div>

        <div v-else class="video-placeholder">
          <p>Video is not available</p>
        </div>
      </div>

      <div v-if="film.submission_password" class="password-section">
        <div class="password-container">
          <span class="password-label">Password:</span>
          <span class="password-value">{{ film.submission_password }}</span>
          <button class="copy-btn" @click="copyPassword">
            Copy
          </button>
        </div>
      </div>

      <!-- Title Section -->
      <div class="title-section">
        <div class="title-left">
          <h2 class="film-title">{{ film.title }}</h2>
          <div class="film-meta">
            <span class="duration">Duration: </span>
            <span class="duration">{{ formatDuration(film.duration) }}</span>
            <span class="separator"> </span>
            <span class="views">
              <img :src="eyeIcon" alt="Views" class="eye-icon" />
              <span>&nbsp;</span>
              {{ film.views || 0 }}
            </span>
          </div>
        </div>
        <div class="title-right">
          <button class="like-btn" @click="handleLike" :disabled="isLikeLoading">
            <img 
              v-if="!isLikeLoading"
              :src="currentLikeIcon" 
              :class="isLiked ? 'like-icon liked' : 'like-animation-icon'"
              alt="Like" 
            />
            <span v-else class="like-loading">...</span>
          </button>
        </div>
      </div>
      <!-- Detail Section -->
      <div class="detail-section">
        <div class="tabs">
          <button 
            :class="['tab-btn', { active: activeTab === 'description' }]"
            @click="activeTab = 'description'"
          >
            Film Description
          </button>
          <button 
            :class="['tab-btn', { active: activeTab === 'team' }]"
            @click="activeTab = 'team'"
          >
            Creative Team
          </button>
        </div>

        <div class="tab-content">
          <div v-if="activeTab === 'description'" class="description">
            {{ film.description }}
          </div>
          <div v-else class="team">
            <div class="team-section" v-if="film.directors.length">
              <h3 class="role-title">Directors</h3>
              <div class="team-members">
                <div v-for="director in film.directors" :key="director.id" class="team-member">
                  <p class="member-name">{{ director.name }}</p>
                  <p v-if="director.introduction" class="member-intro">{{ director.introduction }}</p>
                </div>
              </div>
            </div>

            <div class="team-section" v-if="film.screenwriters.length">
              <h3 class="role-title">Screenwriters</h3>
              <div class="team-members">
                <div v-for="writer in film.screenwriters" :key="writer.id" class="team-member">
                  <p class="member-name">{{ writer.name }}</p>
                  <p v-if="writer.introduction" class="member-intro">{{ writer.introduction }}</p>
                </div>
              </div>
            </div>

            <div class="team-section" v-if="film.producers.length">
              <h3 class="role-title">Producers</h3>
              <div class="team-members">
                <div v-for="producer in film.producers" :key="producer.id" class="team-member">
                  <p class="member-name">{{ producer.name }}</p>
                  <p v-if="producer.introduction" class="member-intro">{{ producer.introduction }}</p>
                </div>
              </div>
            </div>

            <div class="team-section" v-if="film.actors.length">
              <h3 class="role-title">Cast</h3>
              <div class="team-members">
                <div v-for="actor in film.actors" :key="actor.id" class="team-member">
                  <p class="member-name">{{ actor.name }}</p>
                  <p v-if="actor.introduction" class="member-intro">{{ actor.introduction }}</p>
                </div>
              </div>
            </div>

            <div class="team-section" v-if="film.other_crew">
              <p class="member-intro">{{ film.other_crew }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="loading">
      Loading...
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import eyeIcon from '@/assets/eye-icon.png'
import likeIcon from '@/assets/like.png'
import likeAlreadyIcon from '@/assets/like_already.png'
import likeAnimationIcon from '@/assets/heart-swap.gif'
import backIcon from '@/assets/back-button.png'
import { signRequest } from '@/utils/signature'

export default {
  name: 'FilmDetailPage',
  data() {
    return {
      film: null,
      submissionUrl: null,
      activeTab: 'description',
      eyeIcon,
      likeIcon,
      likeAlreadyIcon,
      likeAnimationIcon,
      backIcon,
      isLiked: false,
      isLikeLoading: false,
      likeErrorMessage: null
    };
  },
  computed: {
    shouldShowVideoPlayer() {
      return this.submissionUrl && !this.isEmbeddedVideo && !this.isFilmFreeway;
    },
    isEmbeddedVideo() {
      if (!this.submissionUrl) return false;
      return this.submissionUrl.includes('youtube.com') || 
             this.submissionUrl.includes('youtu.be') ||
             this.submissionUrl.includes('vimeo.com');
    },
    isFilmFreeway() {
      if (!this.submissionUrl) return false;
      return this.submissionUrl.includes('filmfreeway.com');
    },
    getEmbedUrl() {
      if (!this.submissionUrl) return '';
      if (this.submissionUrl.includes('youtube.com') || this.submissionUrl.includes('youtu.be')) {
        const videoId = this.getYouTubeVideoId(this.submissionUrl);
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (this.submissionUrl.includes('vimeo.com')) {
        const videoId = this.getVimeoVideoId(this.submissionUrl);
        return `https://player.vimeo.com/video/${videoId}`;
      }
      return '';
    },
    currentLikeIcon() {
      return this.isLiked ? this.likeAlreadyIcon : this.likeAnimationIcon;
    }
  },
  methods: {
    toggleFullscreen() {
      const videoElement = this.$el.querySelector('.video-player');
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    },
    formatDuration(seconds) {
      if (!seconds) return '';
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60; // Calculate remaining seconds

      // Format hours, minutes, and seconds to always have two digits
      const formattedHours = hours > 0 ? `${hours}:` : '';
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(secs).padStart(2, '0');

      return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    },
    getYouTubeVideoId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },
    getVimeoVideoId(url) {
      const regExp = /vimeo\.com\/([0-9]+)/;
      const match = url.match(regExp);
      return match ? match[1] : null;
    },
    async fetchFilmDetail() {
      try {
        const response = await axios.get(`/public/films/${this.$route.params.id}`);
        if (response.data.code === 200) {
          this.film = response.data.data;
          if (this.film.submission_url) {
            this.handleVideoUrl(this.film.submission_url);
          }
        }
      } catch (error) {
        console.error('Failed to fetch film detail:', error);
      }
    },
    async handleVideoUrl(submissionUrl) {
      this.submissionUrl = submissionUrl;
    },
    copyPassword() {
      navigator.clipboard.writeText(this.film.submission_password)
        .then(() => {
          // Optional: Add some visual feedback that copy was successful
          alert('Password copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy password:', err);
        });
    },
    checkLikeStatus() {
      const likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
      this.isLiked = likedFilms.includes(this.$route.params.id);
    },
    async likeFilm() {
      const filmId = this.$route.params.id;
      try {
        // Prevent multiple clicks
        if (this.isLikeLoading) return;
        this.isLikeLoading = true;
        this.likeErrorMessage = null;
        
        // Create parameters and sign the request
        const params = signRequest({ film_id: filmId });
        
        const response = await axios.post(`/public/films/${filmId}/like`, params);
        if (response.data.code === 200) {
          let likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
          if (!likedFilms.includes(filmId)) {
            likedFilms.push(filmId);
            localStorage.setItem('likedFilms', JSON.stringify(likedFilms));
          }
          this.isLiked = true;
          // 更新点赞数
          if (this.film) {
            this.film.likes = response.data.data.likes;
          }
        }
      } catch (error) {
        console.error('Failed to like film:', error);
        this.likeErrorMessage = this.getLikeErrorMessage(error);
        
        // Show error message to user
        if (this.likeErrorMessage) {
          alert(this.likeErrorMessage);
        }
      } finally {
        this.isLikeLoading = false;
      }
    },
    async unlikeFilm() {
      const filmId = this.$route.params.id;
      try {
        // Prevent multiple clicks
        if (this.isLikeLoading) return;
        this.isLikeLoading = true;
        this.likeErrorMessage = null;
        
        // Create parameters and sign the request
        const params = signRequest({ film_id: filmId });
        
        const response = await axios.post(`/public/films/${filmId}/unlike`, params);
        if (response.data.code === 200) {
          let likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
          likedFilms = likedFilms.filter(id => id !== filmId);
          localStorage.setItem('likedFilms', JSON.stringify(likedFilms));
          this.isLiked = false;
          // 更新点赞数
          if (this.film) {
            this.film.likes = response.data.data.likes;
          }
        }
      } catch (error) {
        console.error('Failed to unlike film:', error);
        this.likeErrorMessage = this.getLikeErrorMessage(error);
        
        // Show error message to user
        if (this.likeErrorMessage) {
          alert(this.likeErrorMessage);
        }
      } finally {
        this.isLikeLoading = false;
      }
    },
    getLikeErrorMessage(error) {
      // Handle different error types
      if (error.response) {
        const { status, data } = error.response;
        
        // Handle specific status codes
        switch (status) {
          case 403:
            return 'Invalid request signature. Please try refreshing the page.';
          case 429:
            return 'Too many requests. Please wait a moment before trying again.';
          case 404:
            return 'Film not found.';
          case 500:
            return 'Server error. Please try again later.';
          default:
            return data.msg || 'An error occurred. Please try again.';
        }
      }
      
      // Network errors
      if (error.request) {
        return 'Network error. Please check your connection and try again.';
      }
      
      // Other errors
      return 'An unexpected error occurred. Please try again.';
    },
    handleLike() {
      if (!this.isLoggedIn()) {
        this.openLoginIframe();
      } else {
        // 切换点赞状态
        if (this.isLiked) {
          this.unlikeFilm();
        } else {
          this.likeFilm();
        }
      }
    },
    isLoggedIn() {
      // Check for accessToken in cookies
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        cookie = cookie.trim();
        // Check if cookie contains accessToken, case insensitive
        if (cookie.toLowerCase().startsWith('accesstoken=')) {
          return true;
        }
      }
      
      // Check if we're in development environment
      const isDevelopment = true;//process.env.NODE_ENV === 'development';
      if (isDevelopment) {
        // Set a default accessToken for development
        document.cookie = "accessToken=eyJhbGciOiJIUzI1NiJ9.eyJyb2xlVHlwZSI6MCwidXNlcklkIjoxODczNTU2NDYyMTQwMTQ5NzYxLCJuaWNrTmFtZSI6InBlaWRvbmcud2VpQGdtYWlsLmNvbSIsImFjY291bnQiOiJwZWlkb25nLndlaUBnbWFpbC5jb20ifQ.mFjjopKkQQ1EfF6JB4AiHtXnvelBKwRbEmqoVr0Ky-s;path=/;domain=.smallrig.com";
        return true;
      }
      
      return false;
    },
    openLoginIframe() {
      // Get browser language or use default "en"
      const browserLang = navigator.language || navigator.userLanguage;
      let siteLang = 'en'; // Default language
      
      // Map browser language to supported languages
      if (browserLang.startsWith('de')) {
        siteLang = 'de';
      } else if (browserLang.startsWith('ko')) {
        siteLang = 'ko';
      } else if (browserLang.startsWith('ja')) {
        siteLang = 'ja';
      }
      
      // Check if there's a language preference stored in localStorage
      const storedLang = localStorage.getItem('siteLang');
      if (storedLang && ['en', 'de', 'ko', 'ja'].includes(storedLang)) {
        siteLang = storedLang;
      }
      
      const loginUrl = `https://login.smallrig.com/#/?m=1&hostname=www.smallrig.com&siteLang=${siteLang}&siteUrl=GLB`;
      
      // Create container div for iframe and back button
      const container = document.createElement('div');
      container.id = 'login-iframe-container'; // Add an ID for easy reference
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100%';
      container.style.height = '100%';
      container.style.zIndex = '1000';
      
      // Create back button
      const backButton = document.createElement('button');
      backButton.innerHTML = 'Close';
      backButton.style.position = 'absolute';
      backButton.style.top = '20px';
      backButton.style.right = '20px';
      backButton.style.zIndex = '1001';
      backButton.style.padding = '8px 16px';
      backButton.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      backButton.style.color = 'white';
      backButton.style.border = 'none';
      backButton.style.borderRadius = '4px';
      backButton.style.cursor = 'pointer';
      backButton.onclick = () => {
        // Remove the entire container and the event listener
        this.removeLoginContainer();
      };
      
      // Create iframe
      const iframe = document.createElement('iframe');
      iframe.src = loginUrl;
      iframe.style.width = window.innerWidth + 'px'; // 设置为屏幕宽度
      iframe.style.height = window.innerHeight + 'px'; // 设置为屏幕高度
      iframe.style.backgroundColor = 'white';
      iframe.style.border = 'none';
      iframe.allowFullscreen = true;
      iframe.style.overflow = 'hidden';
      iframe.setAttribute('allow', 'cross-origin-isolated');
      
      // Append elements
      container.appendChild(backButton);
      container.appendChild(iframe);
      document.body.appendChild(container);

      window.addEventListener('message', this.handleLoginSuccess);
    },
    removeLoginContainer() {
      // Find and remove the container
      const container = document.getElementById('login-iframe-container');
      if (container) {
        container.remove();
      }
      
      // Remove the event listener
      window.removeEventListener('message', this.handleLoginSuccess);
    },
    handleLoginSuccess(event) {
      // Log the entire message event data
      
      if (event.data.type === 'ps_login_success') {
        // Remove the container and event listener
        this.removeLoginContainer();

        // 可以在这里调用点赞方法
        this.likeFilm();
      }
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    }
  },
  created() {
    this.fetchFilmDetail();
    this.checkLikeStatus();
  }
}
</script>

<style scoped>
.film-detail-container {
  background-color: black;
  min-height: 100vh;
  color: white;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  position: sticky;
  top: 0;
  z-index: 100;
}

.back-button {
  cursor: pointer;
  font-size: 1.2rem;
  color: white;
}

.page-title {
  margin: 0;
  font-size: 1rem;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.placeholder {
  width: 24px;
}

.player-section {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;
}

.video-player, .video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.title-section {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 1rem 1rem;
}

.title-left {
  align-items: left;
}

.film-title {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
  text-align: left;
  align-items: left;
}

.film-meta {
  display: flex;
  align-items: left;
  gap: 0.5rem;
  color: white;
}

.like-btn {
  position: relative;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
}

.like-icon {
  width: 24px;
  height: auto;
  margin-right: 4px;
  opacity: 1;
  transition: all 0.3s ease;
}

/* Add this new style for the animated icon */
.like-animation-icon {
  width: 36px; /* Adjust this value as needed */
  height: auto;
  margin-right: 4px;
  opacity: 1;
}

.detail-section {
  padding: 0 1rem 1rem;
}

.tabs {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1.5rem;
}

.tab-btn {
  background: none;
  border: none;
  color: #999;
  padding: 1rem 0;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
}

.tab-btn.active {
  color: white;
}

.tab-btn.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
}

.tab-content {
  line-height: 1.6;
  text-align: left;
  font-size: 1rem;
}

.description {
  white-space: pre-wrap;
}

.loading {
  text-align: center;
  padding: 3rem;
  color: #999;
}

.team-section {
  margin-bottom: 2rem;
}

.role-title {
  color: #999;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.team-members {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.team-member {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.member-name {
  color: white;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.member-intro {
  color: white;
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: pre-wrap;
}

.eye-icon {
  width: 16px;
  height: auto;
  margin-right: 4px;
}

.views {
  display: flex;
  align-items: center;
}

.password-section {
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 1rem;
}

.password-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.password-label {
  color: #999;
}

.password-value {
  color: white;
  font-family: monospace;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.copy-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.copy-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.filmfreeway-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.filmfreeway-button {
  padding: 1rem 2rem;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: opacity 0.3s;
}

.filmfreeway-button:hover {
  opacity: 0.8;
}

.back-icon {
  width: auto;
  height: 20px;
  max-width: 100%;
}

.like-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.like-loading {
  font-size: 16px;
  display: inline-block;
  color: white;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

</style> 