<template>
  <div class="home-container">
    <div class="tab-bar">
      <img :src="require('@/assets/project-logo.png')" alt="Project Logo" class="project-logo">
      <div class="tabs">
        <button @click="goToAbout" :class="{ active: activeTab === 'about' }">About</button>
        <button @click="goToShortlisted" :class="{ active: activeTab === 'shortlisted' }">Shortlisted Films</button>
        <button @click="goToRecommend" :class="{ active: activeTab === 'recommend' }">Recommend</button>
      </div>
    </div>

    <div class="content">
      <RecommendationFilms />
    </div>
  </div>
</template>

<script>
import RecommendationFilms from '@/components/RecommendationFilms.vue'

export default {
  components: {
    RecommendationFilms
  },
  data() {
    return {
      activeTab: 'recommend'
    }
  },
  methods: {
    goToAbout() {
      window.location.href = 'https://www.smallrig.com/global/activity/SmallrigAwards'
    },
    goToShortlisted() {
      this.$router.push('/shortlisted')
    },
    goToRecommend() {
      this.$router.push('/recommend')
    }
  }
}
</script>

<style scoped>
.home-container {
  background-color: black;
  padding-top: 60px; /* Add padding to account for fixed tab bar */
}

.tab-bar {
  position: fixed;  /* Make tab bar fixed */
  top: 0;          /* Position at top */
  left: 0;         /* Position at left */
  right: 0;        /* Stretch to full width */
  z-index: 100;    /* Ensure tab bar stays above other content */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.8rem;
  background-color: black;
  color: white;
}
.project-logo {
  width: 100px;
}
.tabs {
  text-align: right;
  margin-left: auto;
  white-space: nowrap; /* 防止按钮换行 */
  display: inline-flex; /* 使用 inline-flex 让按钮保持在一行 */
}
.tabs button {
  background: none;
  border: none;
  color: white;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  width: auto;
  font-size: 0.6rem;
}
.tabs button.active {
  color: #ff0000;
}
.content {
  padding: 0rem;
}
</style> 