<template>
  <div class="judge-recommendations-container">
    <!-- Fixed navbar -->
    <div class="nav-bar">
      <div class="back-button" @click="$router.go(-1)">
        <img :src="backIcon" alt="Back" class="back-icon">
      </div>
      <h1 class="page-title">Judge Recommendation</h1>
      <div class="placeholder"></div>
    </div>
    
    <!-- Main content container with two sections -->
    <div class="main-container">
      <!-- Static player section (not scrollable) -->
      <div class="static-section" :style="{ height: playerHeight + 'px' }">
        <div class="player-wrapper">
          <video 
            v-if="currentFilm && shouldShowVideoPlayer && !isEmbeddedVideo"
            controls
            class="video-player"
            :key="submissionUrl"
            :src="submissionUrl"
            @loadeddata="handleVideoLoaded"
          >
            This browser cannot play video
          </video>
          
          <iframe
            v-if="currentFilm && isEmbeddedVideo"
            :src="getEmbedUrl"
            class="video-player"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>

          <div v-else-if="isFilmFreeway" class="filmfreeway-container">
            <button 
              @click="openExternalLink(currentFilm.submission_url)"
              class="filmfreeway-button"
            >
              Watch on FilmFreeway
            </button>
          </div>
        </div>
      </div>

      <!-- Scrollable content section -->
      <div class="scrollable-section">
        <div class="content">
          <div v-if="currentFilm?.submission_password" class="password-section">
            <div class="password-container">
              <span class="password-label">Password:</span>
              <span class="password-value">{{ currentFilm.submission_password }}</span>
              <button class="copy-btn" @click="copyPassword">
                Copy
              </button>
            </div>
          </div>

          <!-- Title Section -->
          <div class="title-section">
            <div class="title-left">
              <h2 class="film-title">{{ currentFilm?.title }}</h2>
              <div class="film-meta">
                <span class="duration">Duration: {{ formatDuration(currentFilm?.duration) }}</span>
                <span>&nbsp;</span>
                <img :src="eyeIcon" alt="Views" class="eye-icon" />
                {{ currentFilm?.views || 0 }}
                <button class="like-btn" @click="handleLike">
                  <img 
                    :src="currentLikeIcon" 
                    :class="isLiked ? 'like-icon liked' : 'like-animation-icon'"
                    alt="Like" 
                  />
                </button>
                {{ currentFilm?.likes || 0 }}
              </div>
            </div>
            <div class="title-right">
              <button class="toggle-detail" @click="showDetail = !showDetail">
                <img 
                  :src="showDetail ? upButton : downButton" 
                  class="arrow-icon" 
                  alt="Toggle details"
                >
              </button>
            </div>
          </div>

          <!-- Detail Section -->
          <div v-show="showDetail" class="detail-section">
            <div class="tabs">
              <button 
                :class="['tab-btn', { active: activeDetailTab === 'description' }]"
                @click="activeDetailTab = 'description'"
              >
                Film Description
              </button>
              <button 
                :class="['tab-btn', { active: activeDetailTab === 'team' }]"
                @click="activeDetailTab = 'team'"
              >
                Creative Team
              </button>
            </div>

            <div class="tab-content">
              <div v-if="activeDetailTab === 'description'" class="description">
                {{ currentFilm?.description }}
              </div>
              <div v-else class="team">
                <div v-if="currentFilm?.directors?.length" class="team-section">
                  <h3>Directors</h3>
                  <p>{{ currentFilm.directors.map(d => d.name).join(', ') }}</p>
                </div>
                <div v-if="currentFilm?.screenwriters?.length" class="team-section">
                  <h3>Screenwriters</h3>
                  <p>{{ currentFilm.screenwriters.map(s => s.name).join(', ') }}</p>
                </div>
                <div v-if="currentFilm?.producers?.length" class="team-section">
                  <h3>Producers</h3>
                  <p>{{ currentFilm.producers.map(p => p.name).join(', ') }}</p>
                </div>
                <div class="team-section" v-if="currentFilm?.other_crew">
                    <p class="member-intro">{{ currentFilm?.other_crew }}</p>
                  </div>
              </div>
            </div>
          </div>

          <!-- Playlist Section -->
          <div class="playlist-section" v-if="judge && judge.recommended_films">
            <div class="playlist-header">
              <span>about to play</span>
              <span>play {{ currentFilmIndex + 1 }} in {{ judge.recommended_films.length }} </span>
            </div>
            
            <div class="playlist-divider"></div>
            <div 
              v-for="film in judge.recommended_films" 
              :key="film.id" 
              class="playlist-item"
              :class="{ active: currentFilm?.id === film.id }"
              @click="selectFilm(film)"
            >
              <div class="playlist-poster">
                <img :src="film.cover_image || require('@/assets/default-cover.png')" alt="Film poster">
                <button class="play-button">
                  <span class="play-icon">▶</span>
                </button>
              </div>
              <div class="playlist-info">
                <h3 class="playlist-title">{{ film.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'
import eyeIcon from '@/assets/eye-icon.png'
import likeIcon from '@/assets/like.png'
import likeAlreadyIcon from '@/assets/like_already.png'
import likeAnimationIcon from '@/assets/heart-swap.gif'
import backIcon from '@/assets/back-button.png'
import downButton from '@/assets/down-button.png'
import upButton from '@/assets/up-button.png'
import { signRequest } from '@/utils/signature'

export default {
  name: 'JudgeRecommendations',
  
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      judge: null,
      currentFilm: null,
      submissionUrl: null,
      showDetail: false,
      activeDetailTab: 'description',
      eyeIcon,
      likeIcon,
      likeAlreadyIcon,
      likeAnimationIcon,
      backIcon,
      downButton,
      upButton,
      isLiked: false,
      currentFilmIndex: 0,
      isLikeLoading: false,
      likeErrorMessage: null,
      playerHeight: 350, // Default height
    }
  },

  computed: {
    shouldShowVideoPlayer() {
      if (!this.currentFilm?.submission_url) return false;
      const url = this.currentFilm.submission_url;
      return url.startsWith('http') && 
             !url.includes('vimeo.com') && 
             !url.includes('youtube.com') &&
             !url.includes('youtu.be') &&  // 添加 youtu.be 检查
             !url.includes('filmfreeway.com');
    },
    isEmbeddedVideo() {
      if (!this.currentFilm?.submission_url) return false
      const url = this.currentFilm.submission_url
      return url.includes('vimeo.com') || 
             url.includes('youtube.com') || 
             url.includes('youtu.be')  // 添加对 youtu.be 的支持
    },
    getEmbedUrl() {
      if (!this.currentFilm?.submission_url) {
        return '';
      }
      const url = this.currentFilm.submission_url;
      
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        const videoId = this.getYouTubeVideoId(url);
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (url.includes('vimeo.com')) {
        const videoId = this.getVimeoVideoId(url);
        return `https://player.vimeo.com/video/${videoId}`;
      }
      return '';
    },
    currentLikeIcon() {
      return this.isLiked ? this.likeAlreadyIcon : this.likeAnimationIcon;
    },
    isFilmFreeway() {
      if (!this.currentFilm?.submission_url) return false;
      return this.currentFilm.submission_url.includes('filmfreeway.com');
    }
  },

  methods: {
    async selectFilm(film) {
      try {
        const response = await axios.get(`/public/films/${film.id}`)
        
        if (response.data.code === 200) {
          this.currentFilm = response.data.data
          this.submissionUrl = null
          
          if (this.currentFilm.submission_url?.startsWith('oss://')) {
            await this.fetchOssUrl(this.currentFilm.id)
          } else {
            this.submissionUrl = this.currentFilm.submission_url
          }
          this.checkLikeStatus();
          this.currentFilmIndex = this.judge.recommended_films.findIndex(f => f.id === film.id);
        }
      } catch (error) {
        console.error('Failed to fetch film details:', error)
      }
    },

    async fetchOssUrl(filmId) {
      try {
        const response = await axios.get(`/public/films/${filmId}/stream`)
        if (response.data.code === 200) {
          this.submissionUrl = response.data.data.url
        }
      } catch (error) {
        console.error('Failed to fetch video link:', error)
      }
    },

    handleVideoLoaded(event) {
      const video = event.target
      video.muted = false
    },

    async copyPassword() {
      if (this.currentFilm?.submission_password) {
        try {
          await navigator.clipboard.writeText(this.currentFilm.submission_password)
          alert('Password copied')
        } catch (err) {
          console.error('Failed to copy password:', err)
        }
      }
    },

    formatDuration(seconds) {
      if (!seconds) return '0:00'
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    },

    getYouTubeVideoId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },

    getVimeoVideoId(url) {
      return url.split('/').pop()
    },

    async fetchJudgeData() {
      try {
        const response = await axios.get(`/public/judges/${this.id}`)
        if (response.data.code === 200) {
          this.judge = response.data.data
          if (!this.judge) {
            this.$router.push('/')
          }
        }
      } catch (error) {
        console.error('Failed to fetch judge data:', error)
        this.$router.push('/')
      }
    },

    checkLikeStatus() {
      if (this.currentFilm) {
        const likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
        const currentFilmId = String(this.currentFilm.id);
        const likedFilmsStr = likedFilms.map(id => String(id));
        this.isLiked = likedFilmsStr.includes(currentFilmId);
      }
    },

    handleLike() {
      if (!this.isLoggedIn()) {
        this.openLoginIframe();
      } else {
        if (this.isLiked) {
          this.unlikeFilm();
        } else {
          this.likeFilm();
        }
      }
    },

    isLoggedIn() {
      // Check for accessToken in cookies
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        cookie = cookie.trim();
        // Check if cookie contains accessToken, case insensitive
        if (cookie.toLowerCase().startsWith('accesstoken=')) {
          return true;
        }
      }
      
      // Check if we're in development environment
      const isDevelopment = true;//process.env.NODE_ENV === 'development';
      if (isDevelopment) {
        // Set a default accessToken for development
        document.cookie = "accessToken=eyJhbGciOiJIUzI1NiJ9.eyJyb2xlVHlwZSI6MCwidXNlcklkIjoxODczNTU2NDYyMTQwMTQ5NzYxLCJuaWNrTmFtZSI6InBlaWRvbmcud2VpQGdtYWlsLmNvbSIsImFjY291bnQiOiJwZWlkb25nLndlaUBnbWFpbC5jb20ifQ.mFjjopKkQQ1EfF6JB4AiHtXnvelBKwRbEmqoVr0Ky-s;path=/;domain=.smallrig.com";
        return true;
      }
      
      return false;
    },

    openLoginIframe() {
      // Get browser language or use default "en"
      const browserLang = navigator.language || navigator.userLanguage;
      let siteLang = 'en'; // Default language
      
      // Map browser language to supported languages
      if (browserLang.startsWith('de')) {
        siteLang = 'de';
      } else if (browserLang.startsWith('ko')) {
        siteLang = 'ko';
      } else if (browserLang.startsWith('ja')) {
        siteLang = 'ja';
      }
      
      // Check if there's a language preference stored in localStorage
      const storedLang = localStorage.getItem('siteLang');
      if (storedLang && ['en', 'de', 'ko', 'ja'].includes(storedLang)) {
        siteLang = storedLang;
      }
      
      const loginUrl = `https://login.smallrig.com/#/?m=1&hostname=www.smallrig.com&siteLang=${siteLang}&siteUrl=GLB`;
      
      // Create container div for iframe and back button
      const container = document.createElement('div');
      container.id = 'login-iframe-container'; // Add an ID for easy reference
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100%';
      container.style.height = '100%';
      container.style.zIndex = '1000';
      
      // Create back button
      const backButton = document.createElement('button');
      backButton.innerHTML = 'Close';
      backButton.style.position = 'absolute';
      backButton.style.top = '20px';
      backButton.style.right = '20px';
      backButton.style.zIndex = '1001';
      backButton.style.padding = '8px 16px';
      backButton.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      backButton.style.color = 'white';
      backButton.style.border = 'none';
      backButton.style.borderRadius = '4px';
      backButton.style.cursor = 'pointer';
      backButton.onclick = () => {
        // Remove the entire container and the event listener
        this.removeLoginContainer();
      };
      
      // Create iframe
      const iframe = document.createElement('iframe');
      iframe.src = loginUrl;
      iframe.style.width = window.innerWidth + 'px'; // 设置为屏幕宽度
      iframe.style.height = window.innerHeight + 'px'; // 设置为屏幕高度
      iframe.style.backgroundColor = 'white';
      iframe.style.border = 'none';
      iframe.allowFullscreen = true;
      iframe.style.overflow = 'hidden';
      iframe.setAttribute('allow', 'cross-origin-isolated');
      
      // Append elements
      container.appendChild(backButton);
      container.appendChild(iframe);
      document.body.appendChild(container);

      window.addEventListener('message', this.handleLoginSuccess);
    },

    removeLoginContainer() {
      // Find and remove the container
      const container = document.getElementById('login-iframe-container');
      if (container) {
        container.remove();
      }
      
      // Remove the event listener
      window.removeEventListener('message', this.handleLoginSuccess);
    },

    handleLoginSuccess(event) {
      if (event.data.type === 'ps_login_success') {
        // Remove the container and event listener
        this.removeLoginContainer();
        
        // Call likeFilm after successful login
        this.likeFilm();
      }
    },

    getLikeErrorMessage(error) {
      // Handle different error types
      if (error.response) {
        const { status, data } = error.response;
        
        // Handle specific status codes
        switch (status) {
          case 403:
            return 'Invalid request signature. Please try refreshing the page.';
          case 429:
            return 'Too many requests. Please wait a moment before trying again.';
          case 404:
            return 'Film not found.';
          case 500:
            return 'Server error. Please try again later.';
          default:
            return data.msg || 'An error occurred. Please try again.';
        }
      }
      
      // Network errors
      if (error.request) {
        return 'Network error. Please check your connection and try again.';
      }
      
      // Other errors
      return 'An unexpected error occurred. Please try again.';
    },

    async likeFilm() {
      if (this.currentFilm) {
        const filmId = this.currentFilm.id;
        try {
          // 使用this来设置组件状态
          this.isLikeLoading = true;
          this.likeErrorMessage = null;
          
          // Create parameters and sign the request
          const params = signRequest({ film_id: filmId });
          
          const response = await axios.post(`/public/films/${filmId}/like`, params);
          if (response.data.code === 200) {
            let likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
            if (!likedFilms.includes(filmId)) {
              likedFilms.push(filmId);
              localStorage.setItem('likedFilms', JSON.stringify(likedFilms));
            }
            this.isLiked = true;
            // 更新点赞数
            this.currentFilm.likes = response.data.data.likes;
          }
        } catch (error) {
          console.error('Failed to like film:', error);
          this.likeErrorMessage = this.getLikeErrorMessage(error);
          
          // Show error message to user
          if (this.likeErrorMessage) {
            alert(this.likeErrorMessage);
          }
        } finally {
          this.isLikeLoading = false;
        }
      }
    },

    async unlikeFilm() {
      if (this.currentFilm) {
        const filmId = this.currentFilm.id;
        try {
          // 使用this来设置组件状态
          this.isLikeLoading = true;
          this.likeErrorMessage = null;
          
          // Create parameters and sign the request
          const params = signRequest({ film_id: filmId });
          
          const response = await axios.post(`/public/films/${filmId}/unlike`, params);
          if (response.data.code === 200) {
            let likedFilms = JSON.parse(localStorage.getItem('likedFilms') || '[]');
            likedFilms = likedFilms.filter(id => id !== filmId);
            localStorage.setItem('likedFilms', JSON.stringify(likedFilms));
            this.isLiked = false;
            // 更新点赞数
            this.currentFilm.likes = response.data.data.likes;
          }
        } catch (error) {
          console.error('Failed to unlike film:', error);
          this.likeErrorMessage = this.getLikeErrorMessage(error);
          
          // Show error message to user
          if (this.likeErrorMessage) {
            alert(this.likeErrorMessage);
          }
        } finally {
          this.isLikeLoading = false;
        }
      }
    },

    openExternalLink(url) {
      window.open(url, '_blank');
    },

    updatePlayerHeight() {
      // Calculate player height based on 16:9 aspect ratio (56.25%)
      const viewportWidth = Math.min(window.innerWidth, 800); // Capped at max container width
      this.playerHeight = viewportWidth * 0.5625; // 16:9 aspect ratio
      
      // Apply min/max constraints
      this.playerHeight = Math.max(250, Math.min(450, this.playerHeight));
    },
  },

  async created() {
    await this.fetchJudgeData()
    if (this.judge && this.judge.recommended_films?.length > 0) {
      await this.selectFilm(this.judge.recommended_films[0])
    }
    this.updatePlayerHeight();
    window.addEventListener('resize', this.updatePlayerHeight);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updatePlayerHeight);
  }
}
</script>

<style scoped>
.judge-recommendations-container {
  background: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 100;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Full height minus navbar */
  margin-top: 60px; /* Same as navbar height */
}

.static-section {
  flex-shrink: 0;
  position: relative;
  background: #000;
  width: 100%;
}

.player-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 800px;
  background: #000;
}

.scrollable-section {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  background: #000;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.player-section {
  position: relative; /* Changed from fixed */
  width: 100%;
  height: 100%;
  background: #000;
}

.password-section {
  padding: 0.5rem 1rem;
  background: #000;
  margin-bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.password-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.password-label {
  color: #999;
}

.password-value {
  color: white;
  font-family: monospace;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.copy-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.copy-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.title-section {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #000;
  color: white;
}

.title-left {
  align-items: left;
}

.film-title {
  margin: 0;
  font-size: 1rem;
  text-align: left;
  padding-bottom: 0.5rem;
}

.eye-icon {
  width: 18px;
  height: 18px;
  height: auto;
  margin: 0 4px 0 8px;
}

.like-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin: 0 4px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.like-icon {
  width: 16px;
  height: 16px;
}

.like-animation-icon {
  width: 24px;
  height: 24px;
  opacity: 1;
}

.film-meta {
  font-size: 0.9rem;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.toggle-detail {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 1rem;
}

.arrow-icon {
  width: auto;
  height: 10px;
  max-width: 100%;
}

.detail-section {
  background: #000;
  color: white;
  padding: 10px;
  text-align: left;
}

.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.tab-btn {
  background: none;
  border: none;
  color: #999;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab-btn.active {
  color: white;
  border-bottom: 2px solid white;
}

.tab-content {
  white-space: pre-wrap;
}

.team {
  text-align: left;
}

.team-section {
  margin-bottom: 15px;
}

.team-section h3 {
  color: #999;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.member-intro {
  white-space: pre-wrap;
}

.playlist-section {
  background: #000;
  padding: 10px 0;
}

.playlist-item {
  display: flex;
  height: 120px;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
}

.playlist-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.playlist-item.active {
  background: rgba(255, 255, 255, 0.15);
}

.playlist-poster {
  position: relative;
  width: 90px;
  height: 100%;
}

.playlist-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.playlist-item:hover .play-button {
  opacity: 1;
}

.playlist-info {
  flex: 1;
  padding: 5px 10px;
  color: white;
  display: flex;
  align-items: center;
}

.playlist-title {
  margin: 0;
  font-size: 1rem;
}

.playlist-header {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 0.8rem;
  margin: 0;
  padding: 10px;
  text-align: left;
}

.playlist-divider {
  height: 2px;
  background-color: white;
  margin: 0 0.5rem 0.5rem 0.5rem;
}

.filmfreeway-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}

.filmfreeway-button {
  padding: 1rem 2rem;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: opacity 0.3s;
}

.filmfreeway-button:hover {
  opacity: 0.8;
}

.back-icon {
  width: auto;
  height: 20px;
  max-width: 100%;
}

.page-title {
  margin: 0;
  font-size: 1.2rem;
}

/* Tablets */
@media (max-width: 768px) {
  .page-title {
    font-size: 1rem;
  }
}

/* Mobile phones */
@media (max-width: 480px) {
  .page-title {
    font-size: 0.9rem;
  }
}
</style> 
